// Learn more on https://nuxtjs.org/guide/vuex-store
import { API } from 'aws-amplify';
import {
  messagesByReceiverId,
  listChatRoomUsers,
  getUser
} from '../src/graphql/queries';
import {
  createUser,
  createChatRoom,
  createChatRoomUser,
  createMessage,
  createFile,
  updateMessage,
  updateChatRoom,
} from '../src/graphql/mutations';
import {
  getAdvanceUser,
  chatRoomsByRefIdAndUserId,
  messagesAndFilesByChatRoom,
  getAllChatRoomsForUser
} from '../util/gqlQueries';
import {
  onCreateChatRoom,
  onUpdateMessage,
  onUpdateChatRoom,
  onCreateMessageByReceiverId
} from '../src/graphql/subscriptions';

import moment from 'moment';

let messageSubscription = null;
let roomSubscription = null;
let updateMessageSubscription = null;
let updateRoomsSubscription = null;
let messageSubscriptionUser = null;

// =================================================
// State
// =================================================
export const state = () => ({
  userList: {
    /** List of users belongs to the Chat Room with authenticated user */
  },
  chatRooms: [],
  /** List of Chat Room where authenticated user belongs to */
  chatRoom: {
    /** Chat Room data */
  },
  selectedChatRoom: {
    /** Currently selected Chat Room */
  },
  selectedChatRoomId: null,
  currentUserId: null,
  messages: [
    /** Messages of active Chat Room */
  ],
  error: {
    /** Error of an operation */
  },
  messagesLoaded: false, // flag to indicate the messages have been loaded
  roomsLoaded: false, // flag to indicate the rooms have been loaded
  unreadMessagesCount: 0,
  roomsCount: 0,
  replacedLocal: false,
  nextTokenMessages: null,
  nextTokenChatRooms: null
});

// =================================================
// Mutations
// =================================================
export const mutations = {
  /**
   * Set the selected Chat Room
   * @param {Vuex} store Vuex store
   * @param {Object} chatRoom Selected Chat Room object
   */
  setSelectedChatRoom: (store, chatRoom) => {
    store.selectedChatRoom = chatRoom;
    if (chatRoom === null) {
      store.selectedChatRoomId = null
    }
  },

  /**
   * Set the nextToken
   * @param {Vuex} store Vuex store
   * @param {Object} nextToken the nextToken
   */
  setNextToken: (store, nextToken) => {
    store.nextTokenMessages = nextToken;
  },

  /**
   * Set the nextToken
   * @param {Vuex} store Vuex store
   * @param {Object} nextToken the nextToken
   */
  setNextTokenChatRoom: (store, nextToken) => {
    store.nextTokenChatRooms = nextToken;
  },

  /**
   * Add new messages to the existing list
   * @param {Vuex} store Vuex store
   * @param {Object} newMessages the new messages
   */
  prependToMessages: (store, newMessages) => {
    store.messages.unshift(...newMessages);
  },

  /**
   * Adds new chat rooms to the list
   * @param {Vuex} store Vuex store
   * @param {Object} rooms new rooms
   */
  appendToRooms: (store, rooms) => {
    store.chatRooms.push(...rooms);
  },
  
  /**
   * Set the selected Chat Room
   * @param {Vuex} store Vuex store
   * @param {Object} chatRoom Selected Chat Room object
   */
  setUnreadCount: (store, count) => {
    store.unreadMessagesCount = count;
  },
  
  /**
   * Decreases the unread message count by one
   * @param {Vuex} store Vuex store
   */
  decreaseUnreadCount: (store) => {
    if (store.unreadMessagesCount > 0) {
      store.unreadMessagesCount--;
    }
  },
  /**
   * Set the current user id
   * @param {Vuex} store Vuex store
   * @param {Object} userId current user's id
   */
  setCurrentUserId: (store, userId) => {
    store.currentUserId = userId;
  },

  /**
   * Set the error
   * @param {Vuex} store Vuex store
   * @param {Object} error Error Object
   */
  setError: (store, error) => {
    store.error = error;
  },

  /**
   * Set Chat Room
   * @param {Vuex} store Vuex store
   * @param {Object} chatRooms Chat room Object
   */
  setChatRooms: (store, chatRooms) => {
    store.chatRooms = chatRooms;
  },

  /**
   * Set messages for active Chat Room
   * @param {Vue} store Vuex store
   * @param {List} messages List of message objects
   */
  setMessages: (store, messages) => {
    store.messages = messages;
  },

  /**
   * Set the flag for loader indicating all the rooms are loaded
   * @param {Vue} store Vuex store
   * @param {Boolean} loaded true/ false
   */
  setRoomsLoaded: (store, loaded) => {
    store.roomsLoaded = loaded;
  },

  /**
   * Set the flag for loader indicating all the messages are loaded
   * @param {Vue} store Vuex store
   * @param {Boolean} loaded true/ false
   */
  setMessagesLoaded: (store, loaded) => {
    store.messagesLoaded = loaded;
  },

  /**
   * Set the flag for loader indicating all the messages are loaded
   * @param {Vue} store Vuex store
   * @param {Boolean} loaded true/ false
   */
  setSelectedChatRoomId: (store, roomId) => {
    store.selectedChatRoom = (store.chatRooms.filter(x => x.roomId === roomId))[0]
    store.selectedChatRoomId = roomId;
  },

  /**
   * Set number of rooms for the user
   * @param {Vue} store Vuex store
   * @param {Boolean} count rooms count
   */
  setChatRoomsCount: (store, count) => {
    store.roomsCount = count;
  },

  /**
   * update a message
   * @param {Vue} store Vuex store
   * @param {List} messages new message to be replaced
   */
  updateMessage: (store, message) => {
    const index = store.messages.findIndex((item) => item._id === message._id);
    if (index !== -1) store.messages.splice(index, 1, message);
  },

  /**
   * update a message
   * @param {Vue} store Vuex store
   * @param {List} messages new message to be replaced
   */
  updateChatRoom: (store, room) => {
    const index = store.chatRooms.findIndex(
      (item) => item.refId === room.refId && item.roomId == room.roomId
    );
    if (index !== -1) store.chatRooms.splice(index, 1, room);
  },

  /**
   * increase the number of unread messages
   * @param {Vue} store Vuex store
   * @param {Boolean} loaded true/ false
   */
  increaseMessageCount: (store, loaded) => {
    store.messagesLoaded = loaded;
  },

  /**
   * adds a chat room locally till actual create upon send message
   * @param {Vue} store Vuex store
   * @param {Boolean} newRoom new room object
   */
  addNewLocalChatRoom: (store, newRoom) => {
    store.chatRooms = [...store.chatRooms, newRoom];
  },

  /**
   * replaces local chatRoom
   * @param {Vue} store Vuex store
   * @param {Boolean} newRoom new room object
   */
  removeLocalChatRoom: (store, uploadedChatRoom) => {
    store.chatRooms = [uploadedChatRoom];
  },
   /**
   * replaces local chatRoom
   * @param {Vue} store Vuex store
   * @param {Boolean} newChatRoom new room object
   */
  replaceLocalRoom: (store, newChatRoom) => {
    const index = store.chatRooms.findIndex(
      (item) => item.refId === newChatRoom.refId && item.roomId == newChatRoom.id
    );
    if (index !== -1) store.chatRooms.splice(index, 1, newChatRoom);
  },

   /**
   * replaces local chatRoom
   * @param {Vue} store Vuex store
   * @param {Boolean} newChatRoom new room object
   */
  replaceChatRoomByRoomId: (store, payload) => {
    store.roomsLoaded = false
    const index = store.chatRooms.findIndex(
      (item) => item.roomId == payload.roomId
    );
    if (index !== -1) {
      let oldObj = {...store.chatRooms[index]}
      store.chatRooms.splice(index, 1, payload.room);
      // user has kept the page open with a local chat room selected
      if (store.selectedChatRoomId == oldObj.roomId) {
        store.selectedChatRoomId = payload.room.roomId

        // trigering a computed property
        store.replacedLocal = false
        store.replacedLocal = true
        setTimeout(() => {
          this.commit('setReplacedLocal', false)
        },3000)
        // end of triggering
      }
    }
    store.roomsLoaded = true
  },
  setReplacedLocal: (store, payload) => {
    store.replacedLocal = payload
  }
};

// =================================================
// Mutations
// =================================================
export const getters = {
  /**
   * gets the list of messages
   * @param {Vuex} store Vuex store
   * @param {Object} chatRoom Selected Chat Room object
   */
  getMessages: (state) => {
    return state.messages;
  },
  getChatRooms: (state) => {
    return state.chatRooms;
  },
  getSelectedChatRoomId: (state) => {
    return state.selectedChatRoomId;
  },
  getCurrentUserId: (state) => {
    return state.currentUserId;
  },
};

// =================================================
// Actions
// =================================================
export const actions = {

  async subscribeToUserMessages({ rootState, commit, getters, dispatch }, userId) {
    try {
      if (messageSubscriptionUser == null) {
        messageSubscriptionUser = API.graphql({
          query: onCreateMessageByReceiverId,
          variables: { receiverId: userId }
        }).subscribe((messageData) => {

          const newMessage = messageData.value.data.onCreateMessageByReceiverId;
          const sender = newMessage.user;

          if (rootState.desktopNotificationsAllowed ){
            new Notification('New Message from '+ sender.username , {body : newMessage.content, icon : rootState.notificationIcon });
          }
          dispatch('getUserUnreadMessagesCount', userId)
           .then(res => {
            let existingMsg = getters.getMessages;
            const selectedChatRoomId = getters.getSelectedChatRoomId;
            if (selectedChatRoomId === newMessage.chatRoomId) {
              if (
                existingMsg.filter((x) => x._id === newMessage.id).length === 0
              ) {
                // mark a message as read
                if (newMessage.receiverId === userId && !newMessage.seen) {
                  newMessage.seen = true;
                  commit('decreaseUnreadCount');
                  dispatch('updateMessage', newMessage);
                }
  
                const localDateTime = moment(newMessage.createdAt).local();
                newMessage['_id'] = newMessage['id'];
                newMessage['date'] = localDateTime.format('DD MMMM');
                newMessage['timestamp'] = localDateTime.format('HH:mm');
                newMessage['disableActions'] = true;
                newMessage['disableReactions'] = true;
                commit('setMessages', [...getters.getMessages, newMessage]);
              }
            }
           });
        });
      }
    } catch (e) {
      commit('setError', e);
    }
  },

  async subscribeToMessageUpdates({ commit, getters }) {
    try {
      if (updateMessageSubscription == null) {
        updateMessageSubscription = API.graphql({
          query: onUpdateMessage,
        }).subscribe((messageData) => {
          const newMessage = messageData.value.data.onUpdateMessage;
          let existingMsg = getters.getMessages;
          const selectedChatRoomId = getters.getSelectedChatRoomId;

          if (selectedChatRoomId === newMessage.chatRoomId) {
            if (existingMsg.filter((x) => x._id === newMessage.id).length > 0) {
              const localDateTime = moment(newMessage.createdAt).local();
              newMessage['_id'] = newMessage['id'];
              newMessage['date'] = localDateTime.format('DD MMMM');
              newMessage['timestamp'] = localDateTime.format('HH:mm');
              newMessage['disableActions'] = true;
              newMessage['disableReactions'] = true;
              commit('updateMessage', newMessage);
            }
          }
        });
      }
    } catch (e) {
      commit('setError', e);
    }
  },
  async subscribeToRooms({ commit, getters, dispatch }, userId) {
    try {
      if (roomSubscription == null) {
        roomSubscription = API.graphql({
          query: onCreateChatRoom,
        }).subscribe((response) => {

          const newRoom = {...response.value.data.onCreateChatRoom};
          newRoom['roomId'] = newRoom['id'];
          dispatch('getChatRoomUsers', newRoom.roomId ).then(users=> {
            const curUserArr = users.filter(x => x.userId === getters.getCurrentUserId)
            // if current user is not part of the newly created chat room, exit
            if (curUserArr.length === 0) {
              return  
            }
            const otherUser = (users.filter(x => x.userId !== getters.getCurrentUserId))[0];
            newRoom.roomName = newRoom.roomName + ' ('+otherUser.username+')'
            newRoom['avatar'] = otherUser.user.profileUrl === null ?  'https://ui-avatars.com/api/?background=random&name=' + encodeURI(otherUser.username) : otherUser.user.profileUrl;
            let existingChatRooms = getters.getChatRooms;
            newRoom['users'] = []
            users.forEach((user) => {
              user['_id'] = user.id;
              newRoom.users.push(user);
            });
            // refId is same as loadId
            // check if a chat room already exists
            // checking if the users array of the 
     
            if (
              (existingChatRooms.filter((x) => (x.refId === newRoom.refId && x.roomId == newRoom.roomId))).length > 0
            ) {
              commit('updateChatRoom', newRoom);
            } else {              
              const refArr = existingChatRooms.filter(x => (x.refId === newRoom.refId) && x.users.length> 0 && x.users.every(y => ((y.userId == otherUser.userId) || (y.userId == curUserArr[0].userId))))

              if (refArr.length> 0) {
                commit('replaceChatRoomByRoomId', {room: newRoom, roomId: refArr[0].roomId});
              } else {
                commit('setRoomsLoaded', false);
                commit('setChatRooms', [...getters.getChatRooms, newRoom]);
                commit('setRoomsLoaded', true);
              }
            } 
          }) 
         
        });
      }
    } catch (e) {
      commit('setError', e);
    }
  },

  async subscribeToUpdatingRooms({ commit, getters, dispatch }, userId) {
    try {
      if (updateRoomsSubscription == null) {
        updateRoomsSubscription = API.graphql({
          query: onUpdateChatRoom,
        }).subscribe((response) => {
          const newRoom = response.value.data.onUpdateChatRoom;
          newRoom['roomId'] = newRoom['id'];
          newRoom['users'] = [];
        
          let existingChatRooms = getters.getChatRooms;
          dispatch('getChatRoomUsers', newRoom.roomId ).then(users=> {
            const curUserArr = (users.filter(x => x.userId === getters.getCurrentUserId))

            if (curUserArr.length === 0) {
              return  
            }
            const otherUser = (users.filter(x => x.userId !== getters.getCurrentUserId))[0];
            newRoom.roomName = newRoom.roomName + ' ('+otherUser.username+')'
            newRoom['avatar'] = otherUser.user.profileUrl == null ?  'https://ui-avatars.com/api/?background=random&name=' + encodeURI(otherUser.username) : otherUser.user.profileUrl;
            newRoom.index = +new Date()
            newRoom['users'] = []
            users.forEach((user) => {
              user['_id'] = user.id;
              newRoom.users.push(user);
            });
            if (
              (existingChatRooms.filter((x) => x.refId === newRoom.refId && x.roomId == newRoom.roomId)).length > 0
            ) {
              commit('updateChatRoom', newRoom);
            }
          }) 
         
        });
      }
    } catch (e) {
      commit('setError', e);
    }
  },

  async unsubscribe({ commit }) {
    try {
      commit('setReplacedLocal',false)

      if (roomSubscription) {
        roomSubscription.unsubscribe();
      }
      if (updateMessageSubscription) {
        updateMessageSubscription.unsubscribe();
      }
      if (updateRoomsSubscription) {
        updateRoomsSubscription.unsubscribe();
      }

      roomSubscription = null;
      updateMessageSubscription = null;
      updateRoomsSubscription = null;
    } catch (e) {
      commit('setError', e);
    }
  },

  async unsubscribeToMessages({commit}) {
    try {
      commit('setReplacedLocal',false)
      if (messageSubscriptionUser) {
        messageSubscription.unsubscribe();
      }
      if (roomSubscription) {
        roomSubscription.unsubscribe();
      }
      if (updateMessageSubscription) {
        updateMessageSubscription.unsubscribe();
      }
      if (updateRoomsSubscription) {
        updateRoomsSubscription.unsubscribe();
      }

      roomSubscription = null;
      messageSubscriptionUser = null;
      updateMessageSubscription = null;
      updateRoomsSubscription = null;
    } catch (e) {
      commit('setError', e);
    }
  },

  // Return total unread messages count
  async getUserUnreadMessagesCount({ commit }, userId) {
    return new Promise( (resolve, reject) => {
      try {
        API.graphql({
          query: messagesByReceiverId,
          variables: {receiverId: userId , filter: { seen: { eq: false }} , limit: 21, sortDirection: 'DESC'}
        }).then(response => {
          commit('setUnreadCount', response.data.messagesByReceiverId.items.length)
          resolve(response.data.messagesByReceiverId.items)
        });        
      } catch (e) {
        reject(e)
      }
    })
  },
  

  // Create a new User
  async createUser({ commit }, user) {
    try {
      return await API.graphql({
        query: createUser,
        variables: {
          input: {
            id: user.id,
            username: user.username,
            status: user.status,
            profileUrl: user.profileUrl
          },
        }
      });
    } catch (e) {
      commit('setError', e);
    }
  },

  // Create a new Chat Room
  async createChatRoom({ commit }, { refId, roomName, id , createdBy, target}) {
    try {
      commit('setRoomsLoaded', false);
      const chatRoom = await API.graphql({
        query: createChatRoom,
        variables: {
          input: {
            id,
            refId,
            roomName,
            createdBy,
            target,
            index: +new Date()
          },
        },
      });

      var formattedRoom = {...chatRoom.data.createChatRoom}
      formattedRoom['roomId'] = formattedRoom.id
      formattedRoom['users'] = formattedRoom.chatRoomUsers.items
      commit('setSelectedChatRoom', formattedRoom);
      commit('setRoomsLoaded', true);
      return chatRoom.data.createChatRoom;
    } catch (e) {
      commit('setSelectedChatRoom', {});
      commit('setRoomsLoaded', true);
    }
  },

  // Add `user` to the Chat Room
  async createChatRoomUser({ commit }, { chatRoomId, userId, username }) {
    try {
      return await API.graphql({
        query: createChatRoomUser,
        variables: {
          input: {
            chatRoomId,
            username,
            userId,
          },
        },
      });
    } catch (e) {
      commit('setError', e);
    }
  },

  // Create a new Message in the `chatRoom`
  async sendMessage({ commit, getters }, { chatRoomId, userId, content, fileId, receiverId, id, file }) {
    // add a local message to remove the latency of API call
    const localDateTime = moment().local();
    let newMessage = {
      _id: id,
      chatRoomId,
      senderId: userId,
      receiverId,
      content,
      saved: true,
      distributed: false,
      new: true,
      fileId,
      seen: false,
      date: localDateTime.format('DD MMMM'),
      timestamp: localDateTime.format('HH:mm'),
      disableActions: true,
      disableReactions: true
    }
    if (fileId!= null) {
      newMessage['file'] = file
    }

    commit('setMessages', [...getters.getMessages, newMessage]);
    try {
      return await API.graphql({
        query: createMessage,
        variables: {
          input: {
            id,
            chatRoomId,
            senderId: userId,
            receiverId,
            content,
            saved: true,
            new: true,
            distributed: false,
            fileId,
            seen: false,
          },
        },
      });
    } catch (e) {
      commit('setError', e);
      return {data: e.data};
    }
  },

  // Create a new Message in the `chatRoom`
  async createFile({ commit }, file) {
    try {
      return await API.graphql({
        query: createFile,
        variables: {
          input: file,
        },
      });
    } catch (e) {
      commit('setError', e);
    }
  },

  // List Chat Rooms
  async checkIfRoomExists({ commit }, { userId, refId }) {
    try {
      // commit('setRoomsLoaded', false);
      const user = await API.graphql({
        query: getAdvanceUser,
        variables: {
          id: userId,
        },
      });
      const room = user.data.getUser.chatRoomUser.items
          .map((x) => x.chatRoom)
          .filter((x) => x.refId == refId)
      return ({
        exists: (room.length> 0),  room: (room.length > 0 ? room[0] : null)
      });
    } catch (e) {
      commit('setError', e);
      return {
        exists: false,  room: null
      };
    }
  },

  // Get User
  async getUser({ commit }, userId) {
    try {
      const userData = await API.graphql({
        query: getUser,
        variables: {
          id: userId,
        },
      });
      return userData.data.getUser;
    } catch (e) {
      commit('setError', e);
    }
  },

  async getChatRoomsCount({commit}, room) {
    try {

      let variables = {
        input: { userId: room.userId },
      };

      if (!room.getAll) {
        variables['filter'] = { and: { refId: { eq: room.refId } } };
      }

      const chatRoomData = await API.graphql({
        query: chatRoomsByRefIdAndUserId,
        variables,
      });
      commit('setChatRoomsCount', chatRoomData.data.listChatRooms.items.length)

      return chatRoomData.data.chatRoomsByRefIdAndUserId;
    } catch (e) {
      commit('setError', e);
    }
  },

  // Get Chat Room
  async getChatRooms({ commit }, room) {
    return new Promise( (resolve, reject) => {
      try {
        
        commit('setRoomsLoaded', false);

        let query = getAllChatRoomsForUser;

        let variables = {
          filter: {or:[ {target: {eq: room.userId}}, {createdBy: {eq: room.userId}}]},
        }
        
        if (!room.getAll) {
          variables['filter'] = { and: { refId: { eq: room.refId } , or:[ {target: {eq: room.userId}}, {createdBy: {eq: room.userId}}]} };
        }

        API.graphql({
          query,
          variables,
        }).then( chatRoomData => {
          let rooms = [];
          let chatRooms =[...chatRoomData.data.listChatRooms.items];
          chatRooms.forEach((chatRoom) => {
            chatRoom['roomId'] = chatRoom.id;
            chatRoom['users'] = [...chatRoom.chatRoomUsers.items];
            let usersArr = [];
            chatRoom.users.forEach((user) => {
              user['_id'] = user.id;
              usersArr.push(user);
            });
  
            chatRoom.users = usersArr;
          
            const otherUser = chatRoom.chatRoomUsers.items.filter(
              (x) => x.userId !== room.userId
            );
            
            if (usersArr.length > 0  && otherUser.length > 0) {            
              chatRoom['roomName'] = chatRoom['roomName'] + ' (' + otherUser[0].username + ')';
              chatRoom['avatar'] = otherUser[0].user.profileUrl == null?  'https://ui-avatars.com/api/?background=random&name=' + encodeURI(otherUser[0].username) : otherUser[0].user.profileUrl;
              delete chatRoom.chatRoomUsers;
              delete chatRoom.id;
              rooms.push(chatRoom);
            }
          });
  
          // TODO add pagination for rooms
  
          commit('setChatRooms', rooms);
          commit('setChatRoomsCount', rooms.length)
          commit('setRoomsLoaded', true);
          resolve(chatRoomData.data.listChatRooms);
        });
      } catch (e) {
        commit('setError', e);
        commit('setRoomsLoaded', true);
        reject(e)
      }
   })
  },

  // Get Chat Room
  async getChatRoom({ commit }, room) {
    try {
      const chatRoomData = await API.graphql({
        query: chatRoomsByRefIdAndUserId,
        variables: {
          filter: { and: { refId: { eq: room.refId } } },
          input: { userId: room.userId },
        },
      });
      return chatRoomData.data.listChatRooms.items.length > 0;
    } catch (e) {
      commit('setError', e);
      return false;
    }
  },

  // Get Chat Room
  async getChatRoomUsers({ commit }, chatRoomId) {
    try {
      const chatRoomData = await API.graphql({
        query: listChatRoomUsers,
        variables: {
          filter: { and: { chatRoomId: { eq: chatRoomId } } },
        },
      });
      return chatRoomData.data.listChatRoomUsers.items;
    } catch (e) {
      commit('setError', e);
      return false;
    }
  },

  // Fetch Messages by Chat Room
  async fetchMessages({ commit, dispatch, getters }, payload) {
    try {
      commit('setMessagesLoaded', false);      
      // check if the request if for a new chat room
      if (payload.roomId !== getters.getSelectedChatRoomId) {
        commit('setMessages', []);
        commit('setNextToken', null);
        // this.nextTokenMessages = null;
        payload.nextToken = null;
      }

      commit('setSelectedChatRoomId', payload.roomId);

      const messagesData = await API.graphql({
        query: messagesAndFilesByChatRoom,
        variables: {
          chatRoomId: payload.roomId,
          sortDirection: 'DESC',
          limit: 10,
          nextToken: payload.nextToken
        },
      });

      let messages = [];
      messagesData.data.messagesByChatRoom.items.forEach((message) => {
        // mark a message as read
        if (message.receiverId === payload.userId && !message.seen) {
          message.seen = true;
          message.new = false;
          commit('decreaseUnreadCount');
          dispatch('updateMessage', message);
        }
        message['_id'] = message['id'];
        const localDateTime = moment(message.createdAt).local();
        message['date'] = localDateTime.format('DD MMMM');
        message['timestamp'] = localDateTime.format('HH:mm');
        message['disableActions'] = true;
        message['disableReactions'] = true;
        message['distributed'] = true;
        delete message.id;
        messages.push(message);
      });

      messages = messages.reverse()
      
      if (payload.nextToken != null) {
        commit('prependToMessages', messages)
      } else {
        commit('setMessages', messages)
      } 

      if (messages.length === 0 || messagesData.data.messagesByChatRoom.nextToken === null) {
        // if the nextToken is null that means all the data is already retrieved
        commit('setMessagesLoaded', true);
      } else {
        commit('setMessagesLoaded', false);
        commit('setNextToken', messagesData.data.messagesByChatRoom.nextToken)
      }
    } catch (e) {
      commit('setError', e);
      commit('setMessagesLoaded', true);
    }
  },

   // update a message
   async updateChatRoom({ commit }, chatRoom) {
    try {
      let payload = { 
        id: chatRoom.id,
        name: chatRoom.roomName,
        refId:chatRoom.refId,
        refLink: chatRoom.refLink,
        roomName: chatRoom.roomName,
        avatar: chatRoom.avatar,
        unreadCount: chatRoom.unreadCount,
        index: +new Date(), // get the timestamp
        lastMessageId: chatRoom.lastMessageId
      };

      await API.graphql({
        query: updateChatRoom,
        variables: {
          input: payload,
        },
      });
    } catch (e) {
      commit('setError', e);
    }
  },
  // update a message
  async updateMessage({ commit }, message) {
    try {
      let payload = { 
        content: message.content,
        chatRoomId: message.chatRoomId,
        createdAt: message.createdAt, 
        date: message.date,
        distributed: message.distributed,
        fileId: message.fileId,
        id: message.id,
        new: message.new,
        senderId: message.senderId,
        seen: message.seen,
        system: message.system,
        timestamp: message.timestamp,
        username: message.username,
      };

      payload.distributed = true;

      await API.graphql({
        query: updateMessage,
        variables: {
          input: payload,
        },
      });
    } catch (e) {
      commit('setError', e);
    }
  },

   // eslint-disable-next-line no-unused-vars
  uploadMultipleFiles({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    const formData = new FormData()
    payload.files.forEach((element) => {
      formData.append('files', element)
    });
    formData.set('fileType', payload.type)
    return this.$axios.$post('/api/file/all', formData, {
      headers: {
        'Authorization': `${tokenType} ${token}`,
      }
    })
  }
};
