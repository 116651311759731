let APPLICATION_BASE_URL = '';
const IDENTITYSERVER_BASE_URL = process.env.IDENTITY_URL || 'https://identity.dev.utransport.com.au';
const CLIENT_ID = process.env.CLIENT_ID || 'spa-local-pkce';
const AUTH_FLOW = process.env.AUTH_FLOW || 'code';
const CARRIER_SIGNUP_URL = process.env.CARRIER_SIGNUP_URL || 'https://identity.dev.utransport.com.au/Account/Register?role=Carrier';
if (process.client) {
  APPLICATION_BASE_URL = window.location.origin;
}

const configurations = {
  authConfigCarrierSignUp: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/business-details/information`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigShipperSignup: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/congratulation`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/settings/my-profile`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: `${IDENTITYSERVER_BASE_URL}/Account/Login`,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigHomeLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigLogOut: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/settings/my-profile`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: `${IDENTITYSERVER_BASE_URL}/Account/Login`,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigQuoteLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/gettransportquote`, // selected category will be added to the end of this within the page
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri:  `${IDENTITYSERVER_BASE_URL}/Account/Login`,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigLoadLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/loads-board`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: `${IDENTITYSERVER_BASE_URL}/Account/Login`,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigLoadCreateLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/congratulation`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: `${IDENTITYSERVER_BASE_URL}/Account/Login`,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
    authConfigLoadSignup: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/loads-board`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: CARRIER_SIGNUP_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigJobLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/jobs`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigFavLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/favourites`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: CARRIER_SIGNUP_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigMyQuotesLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/my-quotes`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigMessageLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/messages`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
  authConfigMyReviewsLogin: {
    authority: IDENTITYSERVER_BASE_URL,
    client_id: CLIENT_ID,
    redirect_uri: `${APPLICATION_BASE_URL}/my-reviews`,
    response_type: AUTH_FLOW,
    scope: 'api1.read api1.write openid profile offline_access',
    post_logout_redirect_uri: APPLICATION_BASE_URL,
    loadUserInfo: true,
    state: 'dskjqmoiqQdjnJHOnGn'
  },
}

export default configurations;