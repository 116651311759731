
export const DateType = {
    NONE: 0,
    READY_TO_GO: 1,
    SET_DATE_RANGE: 2,
    NO_SET_DATE: 3,
}

export const getPreferredContactMethod = (value) => {
  switch (value) {
    case 0: return 'Unknown'
    case 1: return 'Phone call'
    case 2: return 'SMS'
    case 3: return 'Email'
    case 4: return 'Quote via UTransport'
  }
}
export const getQuoteAcceptStatus = (status) => {
  switch (status) {
    case 0: return 'Open'
    case 1: return 'Draft'
    case 2: return 'Accepting Quotes'
    case 3: return 'Inactive'
    case 4: return 'Quote Accepted'
    case 5: return 'Withdrawn'
    case 6: return 'Expired'
    case 7: return 'Completed'
    default: return 'Unknown'
  }
}
export const getQuoteAcceptStatusClass = (status) => {
      switch(status){
        case 0 : return 'text accepting-quotes'
        case 1 : return 'text draft'
        case 2 : return 'text accepting-quotes'
        case 3 : return 'text expired'
        case 4 : return 'text finalized'
        case 5 : return 'text withdrawn'
        case 6 : return 'text expired'
        case 7 : return 'text accepting-quotes'
        default : return 'text expired'
      }
}
export const getJobStatus = (status) => {
  switch (status) {
    case 0: return 'None'
    case 1: return 'Quoted'
    case 2: return 'Accepted'
    case 3: return 'Carrier Completed'
    case 4: return 'Shipper Completed'
    case 5: return 'Completed'
    case 6: return 'Cancled'
    case 7: return 'Expired'
    case 8: return 'Load Relisted'
    case 9: return 'System Rejected'
    default: return 'Unknown'
  }
}
export const getJobStatusClass = (status) => {
      switch(status){
        case 0 : return 'text accepting-quotes'
        case 1 : return 'text draft'
        case 2 : return 'text finalized'
        case 3 : return 'text withdrawn'
        case 4 : return 'text withdrawn'
        case 5 : return 'text accepting-quotes'
        case 6 : return 'text expired'
        case 7 : return 'text expired'
        case 8 : return 'text draft'
        case 9 : return 'text expired'
        default : return 'text expired'
      }
}
export const getCustomerTypes = (type) => {
  switch (type) {
    case 1: return 'Personal'
    case 2: return 'Business'
    case 3: return 'Freight-broker'
    default: return 'Unknown'
  }
}
export const getProviderTypes = (type) => {
  switch (type) {
    case 0: return 'Transport Business'
    case 1: return 'Owner Driver'
    case 2: return 'Freight-broker'
    default: return 'Unknown'
  }
}
export const LoadCategories = {
  CARS: 'Cars',
  PALLETISED_FREIGHT_AND_PACKAGES: 'Palletised freight & Packages',
  RVS_CARAVANS_TRAILERS: 'RVS, Caravans, Trailers',
  BOATS: 'Boats',
  MOTORCYCLES: 'Motorcycles',
  HOUSEHOLD_AND_FURNITURE: 'Household & Furniture',
  GENERAL_HAULAGE: 'General Haulage',
  OTHER_VEHICLES: 'Other vehicle',
  HEAVY_HAULAGE_OVERSIZE: 'Heavy / Oversized Haulage',
  MACHINERY_TRANSPORT: 'Machinery Transport',
  SHIPPING_CONTAINERS: 'Shipping Containers',
  PORTABLE_BUILDINGS: 'Portable Buildings',
  TRUCKS_TRAILERS: 'Trailers',
  TRUCKS_TRANSPORT: 'Truck Transport',
  AGRICULTURE: 'Farm Machinery',
  WATER_TANK: 'Water Tanks',
  REFRIDGERATED: 'Refrigerated',
  SIDE_LOADER_TRANSPORT: 'Side Loader Transport',
  WASTE_AND_RECYCLING: 'Waste & Recycling',
  BACKLOADS: 'Backloads',
  UTE_AND_VAN_CARGO:  'Ute & Van Cargo',
  EXCAVATOR_TRANSPORT: 'Excavator Transport',
  OTHER: 'Other'
}

export const TruckCategories = {
  FLAT_TOP_TRAILER: 'Flat Top Trailer',
  DROP_DECK: 'Drop Deck',
  LOW_LOADER: 'Low Loader',
  FLOAT: 'Float',
  PLATFORM: 'Platform',
  TAUTLINER: 'Tautliner',
  PRIME_MOVER: 'Prime Mover',
  TILT_TRAY: 'Tilt Tray',
  RIGID_FLAT_TOP: 'Rigid Flat Top',
  RIGID_PANTEC: 'Rigid Pantech',
  CAR_CARRIER: 'Car Carrier',
  CRANE_TRUCK: 'Crane Truck',
  SIDE_LOADER: 'Side Loader',
  TIPPER: 'Tipper',
  TANKER: 'Tanker',
  LIVESTOCK: 'Livestock',
  REFRIGERATED: 'Refrigerated',
  UTE: 'Ute',
  DRIVER: 'Driver',
  RIGID_TIPPER: 'Rigid Tipper',
  OTHER: 'Other'
}

export const LoadCategoriesDropdown = [
  { value: null, text:  'Select transport category', routeParam: null},
  { value: 'Cars', text: 'Cars', routeParam: 'interstatecartransport'},
  { value: 'Palletised freight & Packages', text:  'Palletised freight & Packages', routeParam: 'palletfreight'},
  { value: 'RVS, Caravans, Trailers', text:  'RVS, Caravans, Trailers', routeParam:  'interstatecaravantransport'},
  { value: 'Boats', text:  'Boats', routeParam: 'interstateboattransport'},
  { value: 'Motorcycles', text:  'Motorcycles', routeParam:  'motorcycles'},
  { value: 'Household & Furniture', text:  'Household & Furniture', routeParam: 'household&furniture'},
  { value: 'General Haulage', text: 'General Haulage', routeParam: 'generalhaulage'},
  { value: 'Truck Transport', text:  'Truck/Trailers Transport', routeParam: 'trucktransport'},
  { value: 'Heavy / Oversized Haulage', text:  'Heavy / Oversized Haulage', routeParam: 'heavyoroversizedhaulage'},
  { value: 'Machinery Transport', text:  'Machinery Transport', routeParam: 'machinerytransport'},
  { value: 'Shipping Containers', text:  'Shipping Containers', routeParam:  'shippingcontainer'},
  { value: 'Portable Buildings', text:  'Portable Buildings', routeParam:  'portablebuildings'},
  { value: 'Trailers', text:  'Trailers', routeParam: 'trailerstransport'},
  { value: 'Agriculture', text:  'Agriculture', routeParam: 'agriculture'},
  { value: 'Water Tanks', text:  'Water Tanks',  routeParam: 'pets&livestock'},
  //{ value: 'Pets & Livestock', text:  'Pets & Livestock', routeParam: 'pets&livestock'},
  // { value: 'Refrigerated', text:  'Refrigerated', routeParam: 'refrigerated'},
  { value: 'Side Loader Transport', text:  'Side Loader Transport', routeParam: 'refrigerated'},
  { value: 'Backloads', text:  'Backloads', routeParam: 'waste&recycling'},
  { value: 'Excavator Transport', text:  'Excavator Transport', routeParam: 'Ute&VanCargo'},
  { value: 'Other', text:  'Other', routeParam: 'otherquote'}
]

export const LoadCategoriesRoutes = [
  { value: 'Cars', text: 'Cars', routeParam: 'car-transport'},
  { value: 'Palletised freight & Packages', text:  'Palletised freight & Packages', routeParam: 'pallet-freight'},
  { value: 'RVS, Caravans, Trailers', text:  'RVS, Caravans, Trailers', routeParam:  'caravan-transport'},
  { value: 'Boats', text:  'Boats', routeParam: 'boat-transport'},
  { value: 'Motorcycles', text:  'Motorcycles', routeParam:  'motorcycle-transport'},
  { value: 'Household & Furniture', text:  'Household & Furniture', routeParam: 'moving-house'},
  { value: 'General Haulage', text: 'General Haulage', routeParam: 'general-haulage'},
  { value: 'Truck Transport', text:  'Truck Transport', routeParam: 'truck-transportation'},
  { value: 'Heavy / Oversized Haulage', text:  'Heavy / Oversized Haulage', routeParam: 'heavy-haulage'},
  { value: 'Machinery Transport', text:  'Machinery Transport', routeParam: 'machinery-transport'},
  { value: 'Shipping Containers', text:  'Shipping Containers', routeParam: 'container-transport'},
  { value: 'Portable Buildings', text:  'Portable Buildings', routeParam: 'portable-buildings'},
  { value: 'Trailers', text:  'Trailers', routeParam: 'trailer-Transport'},
  { value: 'Farm Machinery', text:  'Farm Machinery', routeParam: 'farm-machinery'},
  { value: 'Water Tanks', text:  'Water Tanks', routeParam: 'water-tank-transport'},
  { value: 'Side Loader Transport', text:  'Side Loader Transport', routeParam: 'side-loader'},
  { value: 'Backloads', text:  'Backloads', routeParam: 'backloads'},
  { value: 'Excavator Transport', text:  'Excavator Transport', routeParam: 'cheapest-excavator-quote'},
  { value: 'Other', text:  'Other', routeParam: 'other'}
]

export const getFreightType = (type) => {
  switch (type) {
    case 1: return 'Palletised'
    case 2: return 'Boxes'
    case 3: return 'Other'
    default: return 'Unknown'
  }
}

export const getWasteType = (type) => {
  switch (type) {
    case 1: return 'Recyclable waste'
    case 2: return 'Building materials'
    case 3: return 'Mixed waste'
    case 4: return 'White goods and appliances'
    default: return 'Unknown'
  }
}

  export const getTransportMethod = (type) => {
  switch (type) {
    case 0: return 'Towed'
    case 1: return 'Truck'
    case 2: return 'Truck or towed'
    default: return 'Unknown'
  }
}

  export const getPalletSize = (size) => {
  switch (size) {
    case 0: return 'Standard'
    case 1: return 'Euro small'
    case 2: return 'Euro large'
    case 3: return 'Non standard'
    default: return 'Unknown'
  }
}

  export const getContainerType = (type) => {
  switch (type) {
    case 0: return 'Foot 10'
    case 1: return 'Foot 20'
    case 2: return 'Foot 20 HQ'
    case 3: return 'Foot 40'
    case 4: return 'Foot 40 HQ'
    default: return 'Unknown'
  }
}
  export const getDangerousGoodClass = (type) => {
  switch (type) {
    case 0: return 'None'
    case 1: return 'Extreme'
    case 2: return 'High'
    case 3: return 'Moderate'
    case 4: return 'Low'
    default: return 'Unknown'
  }
}
  export const getCustomerTypeNo = (type) => {
  switch (type) {
    case 'Personal': return 1
    case 'Business': return 2
    case 'FreightBroker': return 3
    default: return 0
  }
}
export const getLocationTypeNo = (type) => {
  switch (type) {
    case 'Residential' : return 1
      case 'Commercial with loading facilities' : return 2
      case 'Commercial without loading facilities' : return 3
      case 'Farm (assumes loading available)' : return 4
      case 'Storage (assumes no loading facilities)' : return 5
      case 'Auction Site (assumes loading available)' : return 6
      case 'Construction Site (loading available)' : return 7
      default : return 0
  }
}

export const getBoatType = (type) => {
  switch (type) {
    case 1: return 'Power Boat'
    case 2: return 'Sailing Boat'
    case 3: return 'Jetski\'s'
    case 4: return 'Yachts'
    case 5: return 'Other'
    default: return 'Unknown'
  }
}

export const ReviewType = {
  SHIPPER_TO_CARRIER : 0,
  CARRIER_TO_SHIPPER: 1
}
export const SubscriptionPlans = {
  1 : {
    dateType: 1,
    type: '1 Month',
    monthlyPrice: 79,
    totPrice: 79.00,
    months: 1,
  },
  2: {
    dateType: 2,
    type: '3 Months',
    monthlyPrice: 69.66,
    totPrice: 209.00,
    months: 3,
  },
  3: {
    dateType: 3,
    type: '6 Months',
    monthlyPrice: 66.5,
    totPrice: 399.00,
    months: 6,
  },
  4: {
    dateType: 4,
    type: 'Annual',
    monthlyPrice: 58.25,
    totPrice: 699.00,
    months: 12,
  }
}
