// Learn more on https://nuxtjs.org/guide/vuex-store

// =================================================
// State
// =================================================
export const state = () => ({

  });
  
  // =================================================
  // Mutations
  // =================================================
  export const mutations = {
  }
  
  // =================================================
  // Actions
  // =================================================
export const actions = {
  submitReview({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$post(`/api/review?jobId=${payload.jobId}&rating=${payload.review.rating}&description=${payload.review.description}`, null, headers)
  },
  // Get list of reviews
  getReviewsList({ state }, payload) {
    return this.$axios.$get(`/api/review?page=${payload.filters.pageNumber}&size=${payload.filters.pageSize}&orderBy=${payload.filters.orderBy}&type=${payload.filters.type}`, {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    })
  }
}