// =================================================
// Actions
// =================================================
export const actions = {
  // eslint-disable-next-line no-unused-vars
  getCarrierJobsListWithfilters({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get(`/carrier?page=${payload.filters.pageNumber}&size=${payload.filters.pageSize}&orderBy=${payload.filters.orderBy}`, headers)
  },
  getShipperJobsListWithfilters({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get(`/shipper?page=${payload.filters.pageNumber}&size=${payload.filters.pageSize}&orderBy=${payload.filters.orderBy}`, headers)
  },

  // Complete job
  completeJob({ state }, payload) {
    return this.$axios.$put(`/complete?jobId=${payload.jobId}`, null, payload.user.token != null ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null)
  },
  // Get completed job selected by admin to view public
  completedJobsToDisplay({ state }, payload) {
    return this.$axios.$get(`/display?page${payload.page}&size=${payload.size}`)
  }
}
