/* eslint-disable no-unused-vars */
// =================================================
// Actions
// =================================================
export const actions = {

  getNewsAlerts() {
    return this.$axios.$get('/api/news-alert/list')
  },
  
}
