
export default {
  props: {
    notificationObj: {
      type: Object,
      default: ()=> {}
    }
  },
  data() {
    return {
      notification: this.notificationObj
    };
  },
  methods: {
    markAsReadandMoveToPage(notification) {
      if (!notification.read){
        notification.read = true
        const copied = {... notification}
        copied.read = true
        this.$store.dispatch('notifications/updateNotification', copied);
      }
      this.$router.push({path: notification.url});
    }
  },
};
