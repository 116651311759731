/* eslint-disable no-unused-vars */
// =================================================
// Actions
// =================================================
export const actions = {
  
  addToFavourites({ state }, payload) {
    let userId = payload?.user?.currentUser?.id
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$post(`/api/favourite?loadId=${payload.loadId}&userId=${userId}`, null, headers)
  },

  getFavouriteListIdsByUser({ state }, payload) {
    let userId = payload?.user?.currentUser?.id
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get(`/api/favourite/list-ids?userId=${userId}`, headers)
  },
  getFavouriteLoadListByUser({ state }, payload) {
    let userId = payload?.user?.currentUser?.id
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get(`/api/favourite/list-loads?userId=${userId}&page=${payload.filters.pageNumber}&size=${payload.filters.pageSize}&orderBy=${payload.filters.orderBy}`, headers)
  },

  removeFromFavourites({ state }, payload) {
    let userId = payload?.user?.currentUser?.id
    return this.$axios.$put(`/api/favourite?loadId=${payload.loadId}&userId=${userId}`,  null, payload.user.token != null ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null)
  },
  checkIsFavourite({ state }, payload) {
    let userId = payload?.user?.currentUser?.id
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get(`/api/favourite?loadId=${payload.loadId}&userId=${userId}`, headers)
  }
}
