// Learn more on https://nuxtjs.org/guide/vuex-store

// =================================================
// State
// =================================================
export const state = () => ({
  });
  
  // =================================================
  // Mutations
  // =================================================
  export const mutations = {
  }
  
  // =================================================
  // Actions
  // =================================================
  export const actions = {
    // Get list of truck
    getTruckList ({ state }, payload) {
      let cate = ''
      let states = ''
      let truckTypes = ''
      if(payload.states.length > 0) {
        for(let item of payload.states) {
          states = `${states}&states=${item}`
        }
      }
      if(payload.category.length > 0) {
        for(let item of payload.category) {
          cate = `${cate}&categories=${encodeURIComponent(item)}`
        }
      }
      if(payload.truckTypes.length > 0) {
        for(let item of payload.truckTypes) {
          truckTypes = `${truckTypes}&truckTypes=${encodeURIComponent(item)}`
        }
      }
      return this.$axios.$get(`/api/trucks?location=${payload.location}&delivery=${payload.delivery}${cate}${states}${truckTypes}&orderBy=${payload.orderBy}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
    },
    getCarrierTruckList({ state }, payload) {
      let cate = ''
      let states = ''
      let truckTypes = ''

      if (payload.states.length > 0) {
        for(let item of payload.states) {
          states = `${states}&states=${item}`
        }
      }
      if(payload.category.length > 0) {
        for(let item of payload.category) {
          cate = `${cate}&categories=${encodeURIComponent(item)}`
        }
      }
      if(payload.truckTypes.length > 0) {
        for(let item of payload.truckTypes) {
          truckTypes = `${truckTypes}&truckTypes=${encodeURIComponent(item)}`
        }
      }
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$get(`/api/trucks/my-trucks?userId=${payload.userId}&location=${payload.location}&delivery=${payload.delivery}${cate}${states}${truckTypes}&orderBy=${payload.orderBy}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`, headers)
    
    },
    createListing({ state }, payload) {
      return this.$axios.$post('/api/trucks', payload.data, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    },
    // edit carrier listing
    updateListing({ state }, payload) {
      return this.$axios.$put('/api/trucks', payload.data, payload.user.token != null ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null)
    }
  }