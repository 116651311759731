
import NotificationItem from './NotificationItem';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    NotificationItem,
    InfiniteLoading,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.notifications;
    },
    unreadCount() {
      return this.$store.state.notifications.unreadCount;
    },
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    isLoading() {
      return this.$store.state.notifications.isLoading;
    },
    nextToken() {
      return this.$store.state.notifications.nextToken;
    },
  },
  created() {
    this.subscribeNotifications();
    this.subscribeToMessages();
    this.getUnreadCount();
    this.getUnreadMessagesCount();
    this.getNotifications();
  },
  methods: {
    subscribeNotifications() {
      this.$store.dispatch('notifications/subscribe', this.currentUser.id);
    },
    subscribeToMessages() {
      this.$store.dispatch('messages/subscribeToUserMessages', this.currentUser.id);
    },
    getUnreadCount() {
      this.$store.dispatch('notifications/getUserUnreadNotificationCount', {
        userId: this.currentUser.id,
      });
    },
    getUnreadMessagesCount() {
      this.$store.dispatch('messages/getUserUnreadMessagesCount', this.currentUser.id);
    },
    getNotifications() {
      this.$store.dispatch('notifications/getUserNotifications', {
        userId: this.currentUser.id,
        nextToken: this.nextToken,
        limit: 10,
      });
    },
    infiniteHandler(state) {
      this.$store
        .dispatch('notifications/getUserNotifications', {
          userId: this.currentUser.id,
          nextToken: this.nextToken,
          limit: 10,
        })
        .then((res) => {
          if (res.nextToken === null) {
            state.complete();
          } else {
            state.loaded();
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          state.complete();
        });
    },
  },
};
