// =================================================
// State
// =================================================
export const state = () => ({
  subscription: null
});

// =================================================
// Mutations
// =================================================
export const mutations = {
  setSubscription: (store, subscription ) => {
    store.subscription =  subscription
  }
}

// =================================================
// Actions
// =================================================
export const actions = {

  // Subscribe
  subscribe({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$post('/api/payment/subscription', payload.data, headers)
  },
  // getSubscriptionAccount
  async getSubscriptionAccount({ commit }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    const sub = await this.$axios.$get('/api/payment/subscription', headers)
    commit('setSubscription', sub)
    return this.$axios.$get('/api/payment/subscription', headers)
  },

    // get Payment intent for last open invoice
  getLastPaymentIntent({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get('/api/payment/subscription/invoice/payment-intent', headers)
  },
  // get Payment plans
  getPaymentPlans({ state }) {
    return this.$axios.$get('/api/payment/plans', null)
  },
  // update plan
  updateSubscription({ state }, payload) {
        const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$put('/api/payment/subscription', payload.data, headers)
  },

  // CancelSubscription
  CancelSubscription({ state }, payload) {
    const headers = payload.user ? {
      headers: {

        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$delete('/api/payment/subscription', headers)
  },

   // get all payment methods
  getAllPaymentMethods({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get('/api/payment/payment-method/all', headers)
  },

  //Submit cancel feedback
  submitCancelFeedback({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$post('/api/payment/subscription/cancel-reason', payload.data, headers)
  },

  //Get payment method intent to add card
  getPaymentMethodIntent({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$post('/api/payment/payment-method', null, headers)
  },

  deletePaymentMethod({ state }, payload) {
    const headers = payload.user ? {
      headers: {

        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$delete(`/api/payment/payment-method?paymentMethodId=${payload.paymentMethodId}`, headers)
  },
    // update default card
    updateDefaultPaymentMethod({ state }, payload) {
      const headers = payload.user ? {
    headers: {
      'Authorization': `${payload.user.tokenType} ${payload.user.token}`
    }
  } : null;
  return this.$axios.$put(`/api/payment/payment-method/default?paymentMethodId=${payload.paymentMethodId}`, null, headers)
},

  updateFailedPayment({ state }, payload) {
    const headers = payload.user ? {
    headers: {
      'Authorization': `${payload.user.tokenType} ${payload.user.token}`
    }
    } : null;
    return this.$axios.$put('/api/payment/fail', null, headers)
  },
}