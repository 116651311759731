import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b56934dc = () => interopDefault(import('../pages/become-a-carrier/index.vue' /* webpackChunkName: "pages/become-a-carrier/index" */))
const _1aa58a77 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _02226569 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _05266594 = () => interopDefault(import('../pages/favourites/index.vue' /* webpackChunkName: "pages/favourites/index" */))
const _13d4fc8c = () => interopDefault(import('../pages/find-transport-carrier/index.vue' /* webpackChunkName: "pages/find-transport-carrier/index" */))
const _0446573e = () => interopDefault(import('../pages/get-transport-quotes/index.vue' /* webpackChunkName: "pages/get-transport-quotes/index" */))
const _215b3662 = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _cb1d9520 = () => interopDefault(import('../pages/loads-board/index.vue' /* webpackChunkName: "pages/loads-board/index" */))
const _824b0150 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _547ece02 = () => interopDefault(import('../pages/my-quotes/index.vue' /* webpackChunkName: "pages/my-quotes/index" */))
const _5e56fd46 = () => interopDefault(import('../pages/my-reviews/index.vue' /* webpackChunkName: "pages/my-reviews/index" */))
const _a6d132ba = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _2ee106c9 = () => interopDefault(import('../pages/public-loads-shipper/index.vue' /* webpackChunkName: "pages/public-loads-shipper/index" */))
const _737779e6 = () => interopDefault(import('../pages/public-trucks-carrier/index.vue' /* webpackChunkName: "pages/public-trucks-carrier/index" */))
const _d95e1828 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _42a30638 = () => interopDefault(import('../pages/settings/change-password/index.vue' /* webpackChunkName: "pages/settings/change-password/index" */))
const _7e3f6cc8 = () => interopDefault(import('../pages/settings/edit-profile/index.vue' /* webpackChunkName: "pages/settings/edit-profile/index" */))
const _71728f7e = () => interopDefault(import('../pages/settings/my-profile/index.vue' /* webpackChunkName: "pages/settings/my-profile/index" */))
const _b08f904a = () => interopDefault(import('../pages/settings/payment-details/index.vue' /* webpackChunkName: "pages/settings/payment-details/index" */))
const _21f4c798 = () => interopDefault(import('../pages/settings/preferences/index.vue' /* webpackChunkName: "pages/settings/preferences/index" */))
const _3ddad10a = () => interopDefault(import('../pages/settings/reviews/index.vue' /* webpackChunkName: "pages/settings/reviews/index" */))
const _0055d913 = () => interopDefault(import('../pages/settings/subscription/index.vue' /* webpackChunkName: "pages/settings/subscription/index" */))
const _598be35c = () => interopDefault(import('../pages/subscription-signup/index.vue' /* webpackChunkName: "pages/subscription-signup/index" */))
const _0e81bc00 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _05cbcf06 = () => interopDefault(import('../pages/thank-you/index.vue' /* webpackChunkName: "pages/thank-you/index" */))
const _236c196c = () => interopDefault(import('../pages/business-details/identity-documents.vue' /* webpackChunkName: "pages/business-details/identity-documents" */))
const _04e575ca = () => interopDefault(import('../pages/business-details/information.vue' /* webpackChunkName: "pages/business-details/information" */))
const _3273060e = () => interopDefault(import('../pages/faqs/articles/index.vue' /* webpackChunkName: "pages/faqs/articles/index" */))
const _08d571d8 = () => interopDefault(import('../pages/faqs/categories/index.vue' /* webpackChunkName: "pages/faqs/categories/index" */))
const _a372ea38 = () => interopDefault(import('../pages/faqs/search/index.vue' /* webpackChunkName: "pages/faqs/search/index" */))
const _473f133b = () => interopDefault(import('../pages/faqs/shipper/index.vue' /* webpackChunkName: "pages/faqs/shipper/index" */))
const _4723cf07 = () => interopDefault(import('../pages/find-transport-carrier/create-truck/index.vue' /* webpackChunkName: "pages/find-transport-carrier/create-truck/index" */))
const _70fb20d6 = () => interopDefault(import('../pages/find-transport-carrier/edit-truck/index.vue' /* webpackChunkName: "pages/find-transport-carrier/edit-truck/index" */))
const _15bc5f47 = () => interopDefault(import('../pages/gettransportquote/backloads.vue' /* webpackChunkName: "pages/gettransportquote/backloads" */))
const _077985aa = () => interopDefault(import('../pages/gettransportquote/boat-transport.vue' /* webpackChunkName: "pages/gettransportquote/boat-transport" */))
const _5ec4b271 = () => interopDefault(import('../pages/gettransportquote/car-transport.vue' /* webpackChunkName: "pages/gettransportquote/car-transport" */))
const _8013f0da = () => interopDefault(import('../pages/gettransportquote/caravan-transport.vue' /* webpackChunkName: "pages/gettransportquote/caravan-transport" */))
const _10950963 = () => interopDefault(import('../pages/gettransportquote/cheapest-excavator-quote.vue' /* webpackChunkName: "pages/gettransportquote/cheapest-excavator-quote" */))
const _5bf7599e = () => interopDefault(import('../pages/gettransportquote/container-transport.vue' /* webpackChunkName: "pages/gettransportquote/container-transport" */))
const _310cfe74 = () => interopDefault(import('../pages/gettransportquote/farm-machinery.vue' /* webpackChunkName: "pages/gettransportquote/farm-machinery" */))
const _5113dc1f = () => interopDefault(import('../pages/gettransportquote/general-freight.vue' /* webpackChunkName: "pages/gettransportquote/general-freight" */))
const _7df9988b = () => interopDefault(import('../pages/gettransportquote/general-haulage.vue' /* webpackChunkName: "pages/gettransportquote/general-haulage" */))
const _8bdc3fac = () => interopDefault(import('../pages/gettransportquote/heavy-haulage.vue' /* webpackChunkName: "pages/gettransportquote/heavy-haulage" */))
const _dc5191ea = () => interopDefault(import('../pages/gettransportquote/Machinery-transport.vue' /* webpackChunkName: "pages/gettransportquote/Machinery-transport" */))
const _0ecbc29c = () => interopDefault(import('../pages/gettransportquote/Motorcycle-transport.vue' /* webpackChunkName: "pages/gettransportquote/Motorcycle-transport" */))
const _0e6ac270 = () => interopDefault(import('../pages/gettransportquote/moving-house.vue' /* webpackChunkName: "pages/gettransportquote/moving-house" */))
const _14c6ba11 = () => interopDefault(import('../pages/gettransportquote/other.vue' /* webpackChunkName: "pages/gettransportquote/other" */))
const _cd184936 = () => interopDefault(import('../pages/gettransportquote/pallet-freight.vue' /* webpackChunkName: "pages/gettransportquote/pallet-freight" */))
const _4b415c9c = () => interopDefault(import('../pages/gettransportquote/portable-buildings.vue' /* webpackChunkName: "pages/gettransportquote/portable-buildings" */))
const _05f6210a = () => interopDefault(import('../pages/gettransportquote/side-loader.vue' /* webpackChunkName: "pages/gettransportquote/side-loader" */))
const _50a1ce60 = () => interopDefault(import('../pages/gettransportquote/Trailer-Transport.vue' /* webpackChunkName: "pages/gettransportquote/Trailer-Transport" */))
const _626fe2a9 = () => interopDefault(import('../pages/gettransportquote/truck-transportation.vue' /* webpackChunkName: "pages/gettransportquote/truck-transportation" */))
const _42f0a2ea = () => interopDefault(import('../pages/gettransportquote/water-tank-transport.vue' /* webpackChunkName: "pages/gettransportquote/water-tank-transport" */))
const _54dd2cbe = () => interopDefault(import('../pages/faqs/articles/_id.vue' /* webpackChunkName: "pages/faqs/articles/_id" */))
const _52460a40 = () => interopDefault(import('../pages/faqs/categories/_id.vue' /* webpackChunkName: "pages/faqs/categories/_id" */))
const _9092af00 = () => interopDefault(import('../pages/congratulation/_userId/index.vue' /* webpackChunkName: "pages/congratulation/_userId/index" */))
const _34bdb33e = () => interopDefault(import('../pages/gettransportquote/_category/index.vue' /* webpackChunkName: "pages/gettransportquote/_category/index" */))
const _1281b4c8 = () => interopDefault(import('../pages/jobs/_jobId/index.vue' /* webpackChunkName: "pages/jobs/_jobId/index" */))
const _47b08de1 = () => interopDefault(import('../pages/loads-board/_loadId/index.vue' /* webpackChunkName: "pages/loads-board/_loadId/index" */))
const _5dfd0ffb = () => interopDefault(import('../pages/my-quotes/_quoteId/index.vue' /* webpackChunkName: "pages/my-quotes/_quoteId/index" */))
const _2f3c8d4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/become-a-carrier",
    component: _b56934dc,
    name: "become-a-carrier"
  }, {
    path: "/contact-us",
    component: _1aa58a77,
    name: "contact-us"
  }, {
    path: "/faqs",
    component: _02226569,
    name: "faqs"
  }, {
    path: "/favourites",
    component: _05266594,
    name: "favourites"
  }, {
    path: "/find-transport-carrier",
    component: _13d4fc8c,
    name: "find-transport-carrier"
  }, {
    path: "/get-transport-quotes",
    component: _0446573e,
    name: "get-transport-quotes"
  }, {
    path: "/jobs",
    component: _215b3662,
    name: "jobs"
  }, {
    path: "/loads-board",
    component: _cb1d9520,
    name: "loads-board"
  }, {
    path: "/messages",
    component: _824b0150,
    name: "messages"
  }, {
    path: "/my-quotes",
    component: _547ece02,
    name: "my-quotes"
  }, {
    path: "/my-reviews",
    component: _5e56fd46,
    name: "my-reviews"
  }, {
    path: "/privacy-policy",
    component: _a6d132ba,
    name: "privacy-policy"
  }, {
    path: "/public-loads-shipper",
    component: _2ee106c9,
    name: "public-loads-shipper"
  }, {
    path: "/public-trucks-carrier",
    component: _737779e6,
    name: "public-trucks-carrier"
  }, {
    path: "/settings",
    component: _d95e1828,
    name: "settings",
    children: [{
      path: "change-password",
      component: _42a30638,
      name: "settings-change-password"
    }, {
      path: "edit-profile",
      component: _7e3f6cc8,
      name: "settings-edit-profile"
    }, {
      path: "my-profile",
      component: _71728f7e,
      name: "settings-my-profile"
    }, {
      path: "payment-details",
      component: _b08f904a,
      name: "settings-payment-details"
    }, {
      path: "preferences",
      component: _21f4c798,
      name: "settings-preferences"
    }, {
      path: "reviews",
      component: _3ddad10a,
      name: "settings-reviews"
    }, {
      path: "subscription",
      component: _0055d913,
      name: "settings-subscription"
    }]
  }, {
    path: "/subscription-signup",
    component: _598be35c,
    name: "subscription-signup"
  }, {
    path: "/terms-and-conditions",
    component: _0e81bc00,
    name: "terms-and-conditions"
  }, {
    path: "/thank-you",
    component: _05cbcf06,
    name: "thank-you"
  }, {
    path: "/business-details/identity-documents",
    component: _236c196c,
    name: "business-details-identity-documents"
  }, {
    path: "/business-details/information",
    component: _04e575ca,
    name: "business-details-information"
  }, {
    path: "/faqs/articles",
    component: _3273060e,
    name: "faqs-articles"
  }, {
    path: "/faqs/categories",
    component: _08d571d8,
    name: "faqs-categories"
  }, {
    path: "/faqs/search",
    component: _a372ea38,
    name: "faqs-search"
  }, {
    path: "/faqs/shipper",
    component: _473f133b,
    name: "faqs-shipper"
  }, {
    path: "/find-transport-carrier/create-truck",
    component: _4723cf07,
    name: "find-transport-carrier-create-truck"
  }, {
    path: "/find-transport-carrier/edit-truck",
    component: _70fb20d6,
    name: "find-transport-carrier-edit-truck"
  }, {
    path: "/gettransportquote/backloads",
    component: _15bc5f47,
    name: "gettransportquote-backloads"
  }, {
    path: "/gettransportquote/boat-transport",
    component: _077985aa,
    name: "gettransportquote-boat-transport"
  }, {
    path: "/gettransportquote/car-transport",
    component: _5ec4b271,
    name: "gettransportquote-car-transport"
  }, {
    path: "/gettransportquote/caravan-transport",
    component: _8013f0da,
    name: "gettransportquote-caravan-transport"
  }, {
    path: "/gettransportquote/cheapest-excavator-quote",
    component: _10950963,
    name: "gettransportquote-cheapest-excavator-quote"
  }, {
    path: "/gettransportquote/container-transport",
    component: _5bf7599e,
    name: "gettransportquote-container-transport"
  }, {
    path: "/gettransportquote/farm-machinery",
    component: _310cfe74,
    name: "gettransportquote-farm-machinery"
  }, {
    path: "/gettransportquote/general-freight",
    component: _5113dc1f,
    name: "gettransportquote-general-freight"
  }, {
    path: "/gettransportquote/general-haulage",
    component: _7df9988b,
    name: "gettransportquote-general-haulage"
  }, {
    path: "/gettransportquote/heavy-haulage",
    component: _8bdc3fac,
    name: "gettransportquote-heavy-haulage"
  }, {
    path: "/gettransportquote/Machinery-transport",
    component: _dc5191ea,
    name: "gettransportquote-Machinery-transport"
  }, {
    path: "/gettransportquote/Motorcycle-transport",
    component: _0ecbc29c,
    name: "gettransportquote-Motorcycle-transport"
  }, {
    path: "/gettransportquote/moving-house",
    component: _0e6ac270,
    name: "gettransportquote-moving-house"
  }, {
    path: "/gettransportquote/other",
    component: _14c6ba11,
    name: "gettransportquote-other"
  }, {
    path: "/gettransportquote/pallet-freight",
    component: _cd184936,
    name: "gettransportquote-pallet-freight"
  }, {
    path: "/gettransportquote/portable-buildings",
    component: _4b415c9c,
    name: "gettransportquote-portable-buildings"
  }, {
    path: "/gettransportquote/side-loader",
    component: _05f6210a,
    name: "gettransportquote-side-loader"
  }, {
    path: "/gettransportquote/Trailer-Transport",
    component: _50a1ce60,
    name: "gettransportquote-Trailer-Transport"
  }, {
    path: "/gettransportquote/truck-transportation",
    component: _626fe2a9,
    name: "gettransportquote-truck-transportation"
  }, {
    path: "/gettransportquote/water-tank-transport",
    component: _42f0a2ea,
    name: "gettransportquote-water-tank-transport"
  }, {
    path: "/faqs/articles/:id",
    component: _54dd2cbe,
    name: "faqs-articles-id"
  }, {
    path: "/faqs/categories/:id",
    component: _52460a40,
    name: "faqs-categories-id"
  }, {
    path: "/congratulation/:userId",
    component: _9092af00,
    name: "congratulation-userId"
  }, {
    path: "/gettransportquote/:category",
    component: _34bdb33e,
    name: "gettransportquote-category"
  }, {
    path: "/jobs/:jobId",
    component: _1281b4c8,
    name: "jobs-jobId"
  }, {
    path: "/loads-board/:loadId",
    component: _47b08de1,
    name: "loads-board-loadId"
  }, {
    path: "/my-quotes/:quoteId",
    component: _5dfd0ffb,
    name: "my-quotes-quoteId"
  }, {
    path: "/",
    component: _2f3c8d4e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
