import Vue from 'vue'
import moment from 'moment'

export function addressFormat(addressObj) {
  if (addressObj) {
    return ((addressObj.suburb && addressObj.suburb) + (addressObj.state && (', ' + addressObj.state)) + (addressObj.postCode && (', ' + addressObj.postCode)));
  } else {
    return '-'
  }
}
 
 export function timeAgo(timestamp) {
   const timeDif = moment.utc(timestamp).diff(moment.utc())
   return moment.duration(timeDif, 'milliseconds').humanize(true)
 }

export function formatDate(dateTime){
   if (dateTime) {
      return moment(dateTime).format('DD/MM/YYYY')
   }
} 

  // 12 Aug 2021
export function ShortMonth(date) {
   if (date) {
     return moment(date).format('DD MMM YYYY')
   }
 }

 // Eg: 12th Mar '21
export function DateForLoadStatus(date) {
   if (date) {
     return moment(date).format('DD MMM \'YY')
   }
 }

 // location type in a load for both collection and delivery
export function locationType(type) {
   switch(type){
      case 1 : return 'Residential'
      case 2 : return 'Commercial with loading facilities'
      case 3 : return 'Commercial without loading facilities'
      case 4 : return 'Farm (assumes loading available)'
      case 5 : return 'Storage (assumes no loading facilities)'
      case 6 : return 'Auction Site (assumes loading available)'
      case 7 : return 'Construction Site (loading available)'
      default : return 'Unknown'
   }
}

// loading facility type in a load for both collection and delivery
export function loadingFacility(type) {
   switch(type) {
      case 1 : return 'Loading Ramp Onsite'
      case 2 : return 'No Loading Facilties'
      case 3 : return 'Forklift onsite for Loading'
      case 4 : return 'Crane onsite for Loading'
      case 5 : return 'Other'
      default : return 'Unknown'
   }
}

// Collection and delivery date type for a load
/**
 * 
 * @param {Object} obj Collection or delivery date object 
 * @returns Collection or Delivert date type
 */
export function loadDateType(obj){
   if (! obj) return 'Unknown'
   const type = obj.dateType
   switch(type) {
      case 1 : return 'Ready Now'
      case 2 : 
         if (obj.startDate === obj.endDate) return 'Must be On ' + moment(obj.endDate).format('DD MMM \'YY')
         else return moment(obj.startDate).format('DD MMM \'YY') + ' - ' + moment(obj.endDate).format('DD MMM \'YY')
      case 3 : return 'No set date'
      default : return 'Unknown'
   }
}

// customer type of the load
export function loadUserType(type) {
   switch(type) {
      case 1 : return 'Sender'
      case 2 : return 'Receiver'
      case 3 : return 'Both'
      default : return 'Unknown'
   }
}

//'customerType' to 'Customer Type'

export const splitFromCapital = (str) => {
   if (typeof str !== 'string') return '';
   return capitalizeFirstLetterOfEachWord(
      str.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g).join(' ')
   );
};

// 'customer type' to 'Customer Type'
export const capitalizeFirstLetterOfEachWord = (str) => {
  if (typeof str !== 'string') return '';
  return str
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
};

const filters = {
   addressFormat,
   timeAgo,
   formatDate,
   ShortMonth,
   DateForLoadStatus,
   locationType,
   loadingFacility,
   loadUserType,
   loadDateType,
   splitFromCapital,
   capitalizeFirstLetterOfEachWord
 }

 export default filters

 Object.keys(filters).forEach((key) => {
   Vue.filter(key, filters[key])
 })
 