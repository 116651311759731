import configurations from '~/plugins/oidc-client-config.js';
import Oidc from 'oidc-client';

export default function ({ $axios }) {
    $axios.setHeader('X-Requested-With', 'XMLHttpRequest')
    $axios.onRequest(config => {
      config.withCredentials = true
    })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      setTimeout(function() {
        var userMgr  = new Oidc.UserManager(configurations.authConfigLogin);
        userMgr.signoutRedirect({
        id_token_hint: localStorage.getItem('id_token'),
        state: 'abcdefg',
      });
      localStorage.clear();
      sessionStorage.clear();
      }, 1000);
    } else if (code === 404) {
      window.location.href = '/notfound'
    }
  })
}
