
import PublicNavbar from '~/components/navbars/PublicNavbar.vue'
import LoginUserNavbar from '~/components/navbars/LoginUserNavbar.vue'
import Footer from '~/components/Footer.vue'
import { UserManager } from 'oidc-client'
import configurations from '~/plugins/oidc-client-config.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  components: {
    PublicNavbar,
    LoginUserNavbar,
    Footer,
    MoonLoader,
},
  data () {
    return {
      showFooter: true,
      showNewsSlider: false,
    }
  },
  computed:{
    isLogin () {
      return this.$store.state.user.isLogin
    },
    isLoading() {
      // optimize the page loading for index page
      if (this.$nuxt.$route.name === 'index'){
        return false;
      }
      return this.$store.state.loading;
    }  
  },
  watch: {
    $route:  {
      handler() {
        const currentPath = this.$route.path;
        if (currentPath.includes('settings')) {
          this.showFooter = false
        } else {
          setTimeout(() => {
            this.showFooter = true
          }, 200)
        }
      },
      deep: true,
      immediate: true
    },
  },
  created() {
    if(!process.server) {
      this.userMgr =  new UserManager(configurations.authConfigLogin)
    }
  },
  mounted() {
    this.getSignedIn()
  },
  methods: {
    // Check if there is any user logged in
    getSignedIn() {
      let self = this
      return new Promise((resolve, reject) => {
        this.userMgr.getUser().then( (user) => {
          if (user == null) {
            self.$store.commit('setLoading', false)
            return resolve(false)
          } else {
            // check the token expiry
            if (Date.now() >= user.expires_at * 1000){
              self.$store.commit('setLoading', false)
              return resolve(false)
            }
            self.$store.commit('user/setToken', user.access_token)
            self.$store.commit('user/setTokenType', user.token_type)
            localStorage.setItem('token', user.access_token)
            this.getUserDetails()
            const payload = {
              user: {
                tokenType: user.token_type,
                token: user.access_token
              }
            }
            this.getSubscriptionAccount(payload)
            return resolve(true)
          }
        }).catch((err) => {
          return reject(err)
        });
      })
    },
    getUserDetails() {
      localStorage.getItem('token')
      this.$store.dispatch('user/getUserDetails')
      this.$store.dispatch('user/getProfileDetails')
    },
    getSubscriptionAccount(payload) {
      this.$store.dispatch('payment/getSubscriptionAccount', payload)
    },
  }
}
