// Learn more on https://nuxtjs.org/guide/vuex-store
import { API } from 'aws-amplify';
import {
  notificationsByDate
} from '../src/graphql/queries';
import {
 updateNotification,
} from '../src/graphql/mutations';

import {
 onCreateNotificationByUserId
} from '../src/graphql/subscriptions';

let notificationSubscription = null;

// =================================================
// State
// =================================================
export const state = () => ({
 notifications: [],
 unreadCount: 0,
 isLoading: true,
 nextToken: null
});

// =================================================
// Mutations
// =================================================
export const mutations = {
 
  /**
   * Set user's notifications
   * @param {Vuex} store Vuex store
   * @param {Object} notifications List of user's notifications
   */
  setNotifications: (store, notifications) => {
    store.notifications = notifications;
  },

  /**
   * Set user's notifications
   * @param {Vuex} store Vuex store
   * @param {Object} notifications List of user's notifications
   */
  setUnreadCount: (store, count) => {
    store.unreadCount = count;
  },
 
  /**
   * Add new notification to the existing list
   * @param {Vuex} store Vuex store
   * @param {Object} newNotification the new notification
   */
  appendToNotifications: (store, newNotifications) => {
    store.notifications.push(...newNotifications);
  },

  /**
   * Set the isLoading flag
   * @param {Vuex} store Vuex store
   * @param {Object} isLoading new value for is loading
   */
  setIsLoading: (store, isLoading) => {
    store.isLoading = isLoading;
  },

  /**
   * Set next token for pagination
   * @param {Vuex} store Vuex store
   * @param {Object} isLoading the next token for pagination
   */
  setNextToken: (store, nextToken) => {
    store.nextToken = nextToken
  },
  
  /**
   * adds one to the unread count
   * @param {Vuex} store Vuex store
   */
  incrementUnreadCount: (store) => {
    store.unreadCount++
  },

  /**
   * reduces one from the unread count
   * @param {Vuex} store Vuex store
   */
  decrementUnreadCount: (store) => {
    store.unreadCount--
  },

  /**
   * finds and updates a notification
   * @param {Vuex} store Vuex store
   */
  updateNotification: (store, notification) => {
    const index = store.notifications.findIndex((item) => item.id === notification.id);
    if (index !== -1) store.notifications.splice(index, 1, notification);
  },
};

// =================================================
// Mutations
// =================================================
export const getters = {
  /**
   * gets the list of notifications
   * @param {Vuex} state Vuex state
   */
  getNotifications: (state) => {
    return state.notifications;
  },
 
};

// =================================================
// Actions
// =================================================
export const actions = {
  async subscribe({ rootState, commit, getters, dispatch }, userId) {
    try {
      if (notificationSubscription == null) {
        notificationSubscription = API.graphql({
          query: onCreateNotificationByUserId,
          variables: { userId }
        }).subscribe((response) => {
          const newNotification = response.value.data.onCreateNotificationByUserId
          commit('incrementUnreadCount')
          commit('setNotifications', [newNotification,...getters.getNotifications ])
          if (rootState.desktopNotificationsAllowed ){
            new Notification('', {body : newNotification.body, icon: rootState.notificationIcon});
          }
        });
      }
    } catch (e) {
      commit('setError', e);
    }
  },

  async unsubscribe({ commit }) {
    try {
     
      if (notificationSubscription) {
        notificationSubscription.unsubscribe();
      }
      notificationSubscription = null;
    } catch (e) {
      commit('setError', e);
    }
  },


  // Get List of notifications
  async getUserNotifications({ commit }, payload) {
    return new Promise( (resolve, reject) => {
      try {
        commit('setIsLoading', true)
        API.graphql({
          query: notificationsByDate,
          variables: { sortDirection: 'DESC', userId: payload.userId, limit: payload.limit, nextToken: payload.nextToken }
        }).then(response => {
          if (payload.nextToken !== null) {
            commit('appendToNotifications', response.data.notificationsByDate.items)
          } else {
            commit('setNotifications', response.data.notificationsByDate.items)
          }
          commit('setIsLoading', false)
          commit('setNextToken', response.data.notificationsByDate.nextToken)
          resolve(response.data.notificationsByDate)
        });        
      } catch (e) {
        commit('setError', e);
        commit('setNextToken', null)
        reject(e)
      }
   })
  },


  // Get List of notifications
  async getUserUnreadNotificationCount({ commit }, payload) {
    return new Promise( (resolve, reject) => {
      try {
        API.graphql({
          query: notificationsByDate,
          variables: { userId: payload.userId , filter: { read: {eq: false}}}
        }).then(response => {
          commit('setUnreadCount',response.data.notificationsByDate.items.filter(x => !x.read).length)
          resolve(response.data.notificationsByDate)
        });        
      } catch (e) {
        reject(e)
      }
    })
  },


  // update a notification
  async updateNotification({ commit }, notification) {
    try {
      delete notification.createdAt
      delete notification.updatedAt
      await API.graphql({
        query: updateNotification,
        variables: {
          input: notification,
        },
      });
      commit('decrementUnreadCount')
      commit('updateNotification', notification)
    } catch (e) {
      commit('setError', e);
    }
  }
};
