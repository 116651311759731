/* eslint-disable no-unused-vars */
// =================================================
// Actions
// =================================================
export const actions = {

  recordButtonClick({ state }, payload) {

    return this.$axios.$post('/api/statistics', payload)
  },
  recordCallsAndEmails({ state }, payload) {
    return this.$axios.$post('/api/statistics/statistic', payload)
  },
  recordTruckCallsAndEmails({ state }, payload) {
    return this.$axios.$post('/api/statistics/truck-statistic', payload)
  },
}
