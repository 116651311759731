// =================================================
// Actions
// =================================================
export const actions = {
  // eslint-disable-next-line no-unused-vars
  getMyQuotesListWithfilters({ state }, payload) {
    const headers = payload.user ? {
      headers: {
        'Authorization': `${payload.user.tokenType} ${payload.user.token}`
      }
    } : null;
    return this.$axios.$get(`/api/quote/carrier/my-quotes?page=${payload.filters.pageNumber}&size=${payload.filters.pageSize}&orderBy=${payload.filters.orderBy}`, headers)
  },
}
