
export default {
  methods:{
    goToHome() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
