/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoomUser = /* GraphQL */ `
  query GetChatRoomUser($id: ID!) {
    getChatRoomUser(id: $id) {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatRoomUsers = /* GraphQL */ `
  query ListChatRoomUsers(
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        chatRoomId
        username
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        read
        title
        body
        type
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatRoomsByRefId = /* GraphQL */ `
  query ChatRoomsByRefId(
    $refId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomsByRefId(
      refId: $refId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByChatRoom = /* GraphQL */ `
  query MessagesByChatRoom(
    $chatRoomId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatRoom(
      chatRoomId: $chatRoomId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByReceiverId = /* GraphQL */ `
  query MessagesByReceiverId(
    $receiverId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByReceiverId(
      receiverId: $receiverId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByDate = /* GraphQL */ `
  query NotificationsByDate(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByDate(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        read
        title
        body
        type
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
