// Learn more on https://nuxtjs.org/guide/vuex-store

// =================================================
// State
// =================================================
export const state = () => ({
    load:{
      /*Load data format */
    }
  });
  
  // =================================================
  // Mutations
  // =================================================
  export const mutations = {
  }
  
  // =================================================
  // Actions
  // =================================================
  export const actions = {
    subimtQuote({ state }, payload) {
        const headers = payload.user ? {
          headers: {
            'Authorization': `${payload.user.tokenType} ${payload.user.token}`
          }
        } : null;
        return this.$axios.$post('/api/quote', payload.data, headers)
    },
    // Get list of quote list for carrier
    getQuoteList ({ state }, payload) {
      return this.$axios.$get(`/api/quote/all?loadId=${payload.loadId}&page=${payload.page}&size=${payload.size}&isCarrier=${payload.isCarrier}`,{
          headers: {
            'Authorization': `${payload.user.tokenType} ${payload.user.token}`
          }
      })
    },
    acceptQuote ({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$put(`/api/quote/accept?loadId=${payload.loadId}&quoteId=${payload.quoteId}`, null, headers)
    },
    getQuoteData ({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$get(`/api/quote?loadId=${payload.loadId}&quoteId=${payload.quoteId}`, headers)
    }
  }