export const getAdvanceUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      status
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          createdAt
          updatedAt
          chatRoom {
            id
            roomName,
            refId,
            chatRoomUsers {
              items {
                user {
                  id
                  username
                  status
                  profileUrl
                }
              }
            }
            lastMessage {
              chatRoomId
              content
              createdAt
              date
              fileId
              new
              id
              seen
              senderId
              timestamp
              updatedAt
              username
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const chatRoomsByRefIdAndUserId = /* GraphQL */ `
  query chatRoomsByRefIdAndUserId( 
  $filter: ModelChatRoomFilterInput
  $limit: Int
  $nextToken: String
  $userId: ModelIDKeyConditionInput ) 
  {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        refId
        name
        refLink
        roomName
        id
        index
        unreadCount
        lastMessage {
          id
          createdAt
          content
          senderId
          chatRoomId
          username
          date
          new
          timestamp
          system
          distributed
          seen
          fileId
          updatedAt
        }
        chatRoomUsers(userId: $userId) {
          items {
            userId
            updatedAt
            createdAt
            chatRoomId
            id
            username
          }
        }
      }
    }
  }
`;

export const chatRoomsByUserId = /* GraphQL */ `
  query chatRoomsByUserId(
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String) 
    {
    listChatRoomUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        chatRoom {
          avatar
          createdAt
          id
          lastMessageId
          name
          refId
          refLink
          roomName
          unreadCount
          updatedAt
          lastMessage {
            chatRoomId
            content
            createdAt
            date
            new
            fileId
            id
            seen
            senderId
            timestamp
            updatedAt
            username
          }
        }
      }
    }
  }
`;


export const messagesAndFilesByChatRoom = /* GraphQL */ `
  query messagesAndFilesByChatRoom(
    $chatRoomId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatRoom(
      chatRoomId: $chatRoomId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        distributed
        seen
        fileId
        updatedAt
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const onCreateCustomChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom {
    onCreateChatRoom {
      id
      chatRoomUsers {
        id
        username
        user {
            createdAt
            id
            updatedAt
            status
            username
            profileUrl
          }
      }
      messages {
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        chatRoomId
        username
        date
        new
        timestamp
        system
        distributed
        seen
        fileId
        updatedAt
      }
      name
      refId
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;

export const getAllChatRoomsForUser = /* GraphQL */`
query getAllChatRoomsForUser(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  )  
  {
  listChatRooms(limit: $limit, filter: $filter, nextToken: $nextToken) {
    nextToken
    items {
      avatar
      createdAt
      id
      index
      lastMessageId
      name
      refId
      roomName
      unreadCount
      updatedAt
      lastMessage {
        id
        createdAt
        content
        senderId
        chatRoomId
        username
        date
        new
        timestamp
        system
        distributed
        seen
        fileId
        updatedAt
      }
      chatRoomUsers {
        items {
          id
          username
          userId
          user {
            createdAt
            id
            updatedAt
            status
            username
            profileUrl
          }
        }
      }
    }
  }
}
`;