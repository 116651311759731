/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomUser(
    $input: CreateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    createChatRoomUser(input: $input, condition: $condition) {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomUser(
    $input: UpdateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    updateChatRoomUser(input: $input, condition: $condition) {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoomUser = /* GraphQL */ `
  mutation DeleteChatRoomUser(
    $input: DeleteChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    deleteChatRoomUser(input: $input, condition: $condition) {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
