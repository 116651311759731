// =================================================
// Actions
// =================================================
export const actions = {
    //Get the shipper preference
    getShipperPreferences({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$get('/api/communication-preference/shipper', headers)
    },
    // Create the shipper preference
    createShipperPreferences({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$post('/api/communication-preference/shipper', payload.data, headers)
    },
    // Update the shipper preference
    updateShipperPreferences({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$put('/api/communication-preference/shipper', payload.data, headers)
    },
    //Get the carrier preference
    getCarrierPreferences({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$get('/api/communication-preference/carrier', headers)
    },
    // Create the carrier preference
    createCarrierPreferences({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$post('/api/communication-preference/carrier', payload.data, headers)
    },
    // Update the carrier preference
    updateCarrierPreferences({ state }, payload) {
      const headers = payload.user ? {
        headers: {
          'Authorization': `${payload.user.tokenType} ${payload.user.token}`
        }
      } : null;
      return this.$axios.$put('/api/communication-preference/carrier', payload.data, headers)
    }
  }
  