
import Cars from '~/components/svgs/quotes/Cars.vue';
import Agriculture from '~/components/svgs/quotes/Agriculture.vue';
import Boats from '~/components/svgs/quotes/Boats.vue';
import Containersportablebuildins from '~/components/svgs/quotes/Containersportablebuildins.vue';
import Generalhaulage from '~/components/svgs/quotes/Generalhaulage.vue';
import Heavyoversizedhaulage from '~/components/svgs/quotes/Heavyoversizedhaulage.vue';
import Householdfurniture from '~/components/svgs/quotes/Householdfurniture.vue';
import Machinerytransport from '~/components/svgs/quotes/Machinerytransport.vue';
import Motorcycles from '~/components/svgs/quotes/Motorcycles.vue';
import Other from '~/components/svgs/quotes/Other.vue';
import Othervehicles from '~/components/svgs/quotes/Othervehicles.vue';
import Palletspackages from '~/components/svgs/quotes/Palletspackages.vue';
import Petslivestock from '~/components/svgs/quotes/Petslivestock.vue';
import Refrigerated from '~/components/svgs/quotes/Refrigerated.vue';
import Rvcaravanstrailers from '~/components/svgs/quotes/Rvcaravanstrailers.vue';
import Trucktrailers from '~/components/svgs/quotes/Trucktrailers.vue';
import Wasterecycling from '~/components/svgs/quotes/Wasterecycling.vue';
import UteAndVanCargo from '~/components/svgs/quotes/UteAndVanCargo.vue';

import {LoadCategories, LoadCategoriesDropdown, LoadCategoriesRoutes} from '../../util/Enum';

export default {
  components: {
    Cars,
    Agriculture,
    Boats,
    Containersportablebuildins,
    Generalhaulage,
    Heavyoversizedhaulage,
    Householdfurniture,
    Machinerytransport,
    Motorcycles,
    Other,
    Othervehicles,
    Palletspackages,
    Petslivestock,
    Refrigerated,
    Rvcaravanstrailers,
    Trucktrailers,
    Wasterecycling,
    UteAndVanCargo
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      LoadCategories,
      isModalVisible: false,
      categoriesList: [],
      loading: true,
      selectedCategory: null,
    };
  },
  created() {
    this.isModalVisible = this.modalShow;
    this.getCategories();
  },
  mounted() {
    this.loading = true;
  },
  methods: {
    // close modal
    close() {
      this.$emit('close');
    },
    // Get list of categories
    getCategories() {
      this.$store
        .dispatch('loads/getCategoriesList', this.$store.state.user)
        .then((res) => {
          this.categoriesList = res;
          this.loading = false;
        });
    },
    goToQuotes() {
      this.$emit('close');
      localStorage.removeItem('itemDetails');
      localStorage.removeItem('collectionAndDelivery');  
      localStorage.removeItem('currentCategory');
      localStorage.removeItem('openTab');
      const categoryArr = LoadCategoriesRoutes.filter(x => x.text == this.selectedCategory)
      this.$router.push({
        path: `/gettransportquote/${encodeURIComponent(categoryArr[0].routeParam)}`,
      });
    },
    assignSelectedDetails(item) {
      this.selectedCategory = item;
      this.goToQuotes();
    }
  },
};
