export const state = () => ({
  popularRoutes: [
    {
      from: 'Brisbane',
      to: 'Melbourne',
      fromPostalCode: 4000,
      toPostalCode: 3000,
      fromFilter: 'Brisbane',
      toFilter:  'Melbourne'
    },
    {
      from: 'Brisbane',
      to: 'Sydney',
      fromPostalCode: 4000,
      toPostalCode: 2000,
      fromFilter: 'Brisbane',
      toFilter: 'Sydney'
    },
    {
      from: 'Canberra',
      to: 'Adelaide',
      fromPostalCode: 2609,
      toPostalCode: 5000,
      fromFilter: 'Canberra Airport',
      toFilter: 'Adelaide'
    },
    {
      from: 'Canberra',
      to: 'Gold Coast',
      fromPostalCode: 2609,
      toPostalCode: 4217,
      fromFilter: 'Canberra Airport',
      toFilter: 'Surfers Paradise'
    },
    {
      from: 'Darwin',
      to: 'Brisbane',
      fromPostalCode: 800,
      toPostalCode: 4000,
      fromFilter: 'Darwin',
      toFilter: 'Brisbane'
    },
    {
      from: 'Melbourne',
      to: 'Adelaide',
      fromPostalCode: 3000,
      toPostalCode: 5000,
      fromFilter: 'Melbourne',
      toFilter: 'Adelaide'
    },
    {
      from: 'Melbourne',
      to: 'Brisbane',
      fromPostalCode: 3000,
      toPostalCode: 4000,
      fromFilter: 'Melbourne',
      toFilter: 'Brisbane'
    },
    {
      from: 'Melbourne',
      to: 'Sydney',
      fromPostalCode: 3000,
      toPostalCode: 2000,
      fromFilter: 'Melbourne',
      toFilter: 'Sydney'
    },
    {
      from: 'Sydney',
      to: 'Melbourne',
      fromPostalCode: 2000,
      toPostalCode: 3000,
      fromFilter: 'Sydney',
      toFilter: 'Melbourne'
    },
  ]
})

export const mutations = {
}

export const actions = {

  getStates () {
    return this.$axios.$get('/api/lookup/states')
  },

  // eslint-disable-next-line no-unused-vars
  getSuburbs ({ state }, payload) {
    return this.$axios.$get(`/api/lookup/suburb/${payload.searchString}`)
  },
  // Get list of make for car
  getCarMakeList () {
    return this.$axios.$get('/api/lookup/car/makes')
  },
  // Get list of model for car
  getCarModelList ({ commit }, make) {
    return this.$axios.$get(`/api/lookup/car/makes/${make}/models`)
  },
  // Get list of body type for car
  getCarBodyTypeList ({ commit }, payload) {
    return this.$axios.$get(`/api/lookup/car/makes/${payload.make}/models/${payload.model}/body-types`)
  },
   
}