export const state = () => ({
  auth: null,
  loading: true,
  desktopNotificationsAllowed: false,
  notificationIcon: 'https://utransport-static-files.s3.ap-southeast-2.amazonaws.com/u-logo.png'
})

export const actions = {

}

export const mutations = {
  SET_AUTH (store, data) {
    store.auth = data
  },
  setLoading(store, data) {
    store.loading = data
  },
  setDesktopNotification(store, isAllowed) {
    store.desktopNotificationsAllowed = isAllowed;
  }
}