
import { UserManager } from 'oidc-client';
import configurations from '~/plugins/oidc-client-config.js';
import GetQuoteModal from '~/components/modal/GetQuoteModal';
import NotificationList from '~/components/notifications/NotificationList';

export default {
  components: {
    GetQuoteModal,
    NotificationList
  },
  data() {
    return {
      isOnboarding: false,
      displayName: '',
      activeSettingsPage: false,
      activeStatus: false,
      isModalVisible: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    unreadCount() {
      return this.$store.state.messages.unreadMessagesCount;
    },
  },
  watch: {
    currentUser: {
      handler() {
        this.setCurrentUserName();
      },
      deep: true,
    },
    $route: {
      handler() {
        const currentPath = this.$route.path;
        if (
          currentPath.includes('business-details') ||
          currentPath.includes('congratulation')
        ) {
          this.isOnboarding = true;
        } else {
          this.isOnboarding = false;
        }

        if (currentPath.includes('settings')) {
          this.activeSettingsPage = true;
        } else {
          this.activeSettingsPage = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!process.server) {
      this.userMgr = new UserManager(configurations.authConfigLogin);
    }
    this.setCurrentUserName();
    
  },
  mounted() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then(permission => {
          this.$store.commit('setDesktopNotification', permission ==='granted')
      })
    } else if (Notification.permission === 'granted') {
      this.$store.commit('setDesktopNotification', true)
    }
  },
  methods: {
    // Login out from the account
    async logout() {
      var user = await this.userMgr.getUser();
      var token = localStorage.getItem('id_token');

      if(!user && this.currentUser){
        token = this.currentUser.token;
      }

      if(user){
        token = user.id_token;
      }
      this.isOnboarding = false;
      this.userMgr.signoutRedirect({
        id_token_hint:  token,
        state: 'abcdefg',
      });
      localStorage.clear();
      sessionStorage.clear();
    },
    goToProfile() {
      this.$router.push({
        path: '/settings/my-profile',
      });
    },
    setCurrentUserName() {
      if (this.currentUser !== null) {
        this.displayName = `${
          this.currentUser.firstName != null ? this.currentUser.firstName : ''
        } ${
          this.currentUser.lastName != null ? this.currentUser.lastName : ''
        }`;
      }
    },
    showQuoteModal() {
      this.isModalVisible = true;
    },
    closeQuoteModal() {
      this.isModalVisible = false;
    },
  },
};
