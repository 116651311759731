
import Logo from '~/components/Logo.vue';
import GetQuoteModal from '~/components/modal/GetQuoteModal';

export default {
  components: {
    Logo,
    GetQuoteModal
  },
  data() {
    return {
      isModalVisible: false,
      isShipper: false,
      popularLinks: [
        {
          display: 'Freight Loads Available',
          to: '/loads-board'
        },
        {
          display: 'Interstate Boat Transport',
           to: '/gettransportquote/interstateboattransport'
        },
        {
          display: 'Interstate Car Transport',
          to: '/gettransportquote/interstatecartransport'
        },
        {
          display: 'Interstate Caravan Transport',
          to: '/gettransportquote/interstatecaravantransport'
        },
        {
          display: 'Load Board',
          to: '/loads-board'
        },
        {
          display: 'Machinery Transport',
          to: '/gettransportquote/machinerytransport'
        },
        {
          display: 'Pallet Freight Available',
          to: `/loads-board?category=${encodeURIComponent('Palletised freight & Packages')}`
        },
        {
          display: 'Shipping Container Transport',
          to: '/gettransportquote/shippingcontainer'
        },
        {
          display: 'Truck Transport',
          to: '/gettransportquote/trucktransport'
        }
      ]
    }
  },
  computed:{
    popularRoutes() {
      return this.$store.state.lookup.popularRoutes;
    },
    currentUser() {
      return this.$store.state.user.currentUser;
    },
  },
  watch: {
    currentUser: {
      handler() {
        if (this.currentUser) {
          if (this.currentUser.role === 'Shipper') {
            this.isShipper = true
          }
          else {
            this.isShipper = false
          }
        }
        else {
          this.isShipper = false
        }
      },
      deep: true,
    }
  },
  methods:{
    goToHowItWorks() {
      this.$router.push({
        path: '/get-transport-quotes'
      })
    },
    goToLoads() {
      this.$router.push({
        path: '/loads-board'
      })
    },
    showQuoteModal() {
      this.isModalVisible = true
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    closeQuoteModal() {
      this.isModalVisible = false;
    },
    redirectToLoadsBoard(route){
      let path = 'loads-board';
      if (this.isShipper) {
        path = 'public-loads-shipper';
      }
      this.$router.push({
        path: `/${path}?from=${encodeURI(route.from.toLowerCase())}&to=${encodeURI(route.to.toLowerCase())}`
      });
    },
    redirectToLoadsBoardCategory(categoryPath){
      if(this.isShipper && categoryPath.includes('loads-board')) {
        categoryPath = categoryPath.replace('loads-board', 'public-loads-shipper')
      }
      this.$router.push({
        path: categoryPath
      });
    },
    redirectToContactus(){
      this.$router.push({
        path: '/contact-us'
      });
    },
    redirectToFAQ(){
      this.$router.push({
        path: '/faqs'
      });
    },
    redirectToShipperFAQ(){
      this.$router.push({
        path: '/faqs/shipper'
      });
    },
    redirectToTermsAndConditions(){
      this.$router.push({
        path: '/terms-and-conditions'
      });
    },
    redirectToPrivacyPolicy(){
      this.$router.push({
        path: '/privacy-policy'
      });
    }
  }
};
