/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotificationByUserId = /* GraphQL */ `
  subscription OnCreateNotificationByUserId($userId: ID!) {
    onCreateNotificationByUserId(userId: $userId) {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageByReceiverId = /* GraphQL */ `
  subscription OnCreateMessageByReceiverId($receiverId: ID!) {
    onCreateMessageByReceiverId(receiverId: $receiverId) {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      username
      status
      profileUrl
      chatRoomUser {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatRoomUser = /* GraphQL */ `
  subscription OnCreateChatRoomUser {
    onCreateChatRoomUser {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoomUser = /* GraphQL */ `
  subscription OnUpdateChatRoomUser {
    onUpdateChatRoomUser {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoomUser = /* GraphQL */ `
  subscription OnDeleteChatRoomUser {
    onDeleteChatRoomUser {
      id
      userId
      chatRoomId
      username
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom {
    onCreateChatRoom {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom {
    onUpdateChatRoom {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom {
    onDeleteChatRoom {
      id
      chatRoomUsers {
        items {
          id
          userId
          chatRoomId
          username
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        nextToken
      }
      lastMessageId
      lastMessage {
        id
        createdAt
        content
        senderId
        receiverId
        chatRoomId
        username
        date
        timestamp
        system
        saved
        distributed
        seen
        new
        fileId
        file {
          id
          name
          size
          type
          url
          preview
          createdAt
          updatedAt
        }
        user {
          id
          username
          status
          profileUrl
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageId
          name
          refId
          createdBy
          target
          refLink
          roomName
          avatar
          unreadCount
          index
          createdAt
          updatedAt
        }
        updatedAt
      }
      name
      refId
      createdBy
      target
      refLink
      roomName
      avatar
      unreadCount
      index
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      createdAt
      content
      senderId
      receiverId
      chatRoomId
      username
      date
      timestamp
      system
      saved
      distributed
      seen
      new
      fileId
      file {
        id
        name
        size
        type
        url
        preview
        createdAt
        updatedAt
      }
      user {
        id
        username
        status
        profileUrl
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageId
        lastMessage {
          id
          createdAt
          content
          senderId
          receiverId
          chatRoomId
          username
          date
          timestamp
          system
          saved
          distributed
          seen
          new
          fileId
          updatedAt
        }
        name
        refId
        createdBy
        target
        refLink
        roomName
        avatar
        unreadCount
        index
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile {
    onCreateFile {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile {
    onUpdateFile {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile {
    onDeleteFile {
      id
      name
      size
      type
      url
      preview
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      userId
      read
      title
      body
      type
      url
      createdAt
      updatedAt
    }
  }
`;
