export const state = () => ({
  currentUser: null,
  token: null,
  tokenType: 'Bearer',
  isLogin: false,
  profile: null
})

export const getters = {
  currentUser: state => state.currentUser,
  profile: state => state.profile
}

export const mutations = {
  setUser(state, payload) {
    state.currentUser = payload
  },
  setToken(state, payload) {
    state.token = payload
    state.isLogin = payload !== null
  },
  setTokenType(state, payload) {
    state.tokenType = payload
  },
  setUserProfile(state, payload) {
    state.profile = payload
  },
}

export const actions = {
  // Get generate customer key
  getUserDetails({ commit, state }) {
    commit('setLoading', true, { root: true })
    
    const token = state.token ? state.token : localStorage.getItem('token')
    const tokenType = state.tokenType
    commit('setToken', token)
    return new Promise((resolve, reject) => {
        this.$axios.$get('/api/account/user',{
          headers: {
            'Authorization': `${tokenType} ${token}`
          }
        }).then((res) => {  
            commit('setUser', res)
            if (!res.isRegistrationCompleted && res.role === 'Carrier') {
              this.$router.push({
                path: '/business-details/information',
              });
            }
            setTimeout(() => {
              commit('setLoading', false, { root: true })
            }, 200)
            resolve(res)
        }).catch((err) => {
            reject(err)
            setTimeout(() => {
              commit('setLoading', false, { root: true })
            }, 200)
        })
        commit('setLoading', false, { root: true })
      })
  },
  // eslint-disable-next-line no-unused-vars
  updateUserDetails ({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    return this.$axios.$put('/api/account/user', payload, {
      headers: {
        'Authorization': `${tokenType} ${token}`
      }
    })
  },
  // eslint-disable-next-line no-unused-vars
  uploadFile ({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    const formData = new FormData()
    formData.set('file', payload.file)
    return this.$axios.$post(`/api/file?type=${payload.type}`, formData, {
      headers: {
        'Authorization': `${tokenType} ${token}`
      }
    })
  },
  // eslint-disable-next-line no-unused-vars
  uploadMultipleFiles({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    const formData = new FormData()
    payload.files.forEach((element) => {
    formData.append('files', element)
    });
    formData.set('fileType', payload.type)
    return this.$axios.$post('/api/file/all', formData, {
      headers: {
        'Authorization': `${tokenType} ${token}`,
      }
    })
  },
  // Create new device
  // eslint-disable-next-line no-unused-vars
  saveUserBusinessInformation ({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    return this.$axios.$post(`/api/account/business-information?email=${payload.email}`, payload.data, {
      headers: {
        headers: {'Content-Type': 'multipart/form-data' },
        'Authorization': `${tokenType} ${token}`
      }
    })
  },
   // Create new device
   // eslint-disable-next-line no-unused-vars
  changePassword({ state }, payload) {
    return this.$axios.$post('/api/account/changepassword', payload,{
      headers: {
        headers: {'Content-Type': 'multipart/form-data' },
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  // eslint-disable-next-line no-unused-vars
  getBusinessInformation ({ state }) {
    return this.$axios.$get('/api/account/business-information',{
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  // Query business information by ABN (ABN lookup)
  getABNDetails ({ commit }, payload) {
    return this.$axios.$get(`api/abn-lookup/${payload}`)
  },
  resendEmail ({ state }, payload) {
    const user = state.currentUser
    const token = state.token
    const tokenType = state.tokenType
    return this.$axios.$post(`/api/account/resendemail?email=${payload}&userId=${user.id}`, payload.data, {
      headers: {
        'Authorization': `${tokenType} ${token}`
      }
    })
  },
  getProfileDetails ({ commit, state }) {
    return new Promise((resolve, reject) => {
      this.$axios.$get('/api/account/profile',{
        headers: {
          'Authorization': `${state.tokenType} ${state.token}`
        }
      }).then((res) => {
        commit('setUserProfile', res.result);
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getProfileDetailsById ({ state }, id) {
    return this.$axios.$get(`/api/account/profile/${id}`,{
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  updateProfileDetails ({ state }, payload) {
    return this.$axios.$put('/api/account/profile', payload, {
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  getContactDetails ({ state }) {
    return this.$axios.$get('/api/account/contact',{
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  getProfileImage ({ state }, userId) {
    return this.$axios.$get(`/api/account/profile/${userId}/image`,{
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  updateContactDetails ({ state }, payload) {
    return this.$axios.$put('/api/account/contact', payload, {
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  checkUserEmailExsitence ({ state }, userName) {
    return this.$axios.$get(`/api/account/userexist/${userName}`,{
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  getUserCompletedJobs ({ state }) {
    return this.$axios.$get('/completed-count',{
      headers: {
        'Authorization': `${state.tokenType} ${state.token}`
      }
    })
  },
  //get public file url
  getFileUrl ({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    return this.$axios.$post(`/api/file/presign?url=${payload.url}`, {
      headers: {
        'Authorization': `${tokenType} ${token}`
      }
    })
  },
  getAllPresignUrls ({ state }, payload) {
    const token = state.token
    const tokenType = state.tokenType
    return this.$axios.$post('/api/file/presign/all', payload, {
      headers: {
        'Authorization': `${tokenType} ${token}`
      }
    })
  }
}
