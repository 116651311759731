export default function(req, res, next) {
    const redirects = [
      {
        from: "/loadsboard/*",
        replacefrom: "loadsboard",
        to: "loads-board"
        
      },
      {
        from: "/findtransportcarrier/*",
        replacefrom: "findtransportcarrier",
        to: "find-transport-carrier"
        
      },
      {
        from: "/gettransportquotes/*",
        replacefrom: "gettransportquotes",
        to: "get-transport-quotes"
        
      }
    ]
    
    const redirect = redirects.find((r) => req.url.match(r.from))
    
    if (redirect) {
      var nexturl = req.url.replace(redirect.replacefrom, redirect.to)
      res.writeHead(301, { Location: nexturl })
      res.end()
    } else {
      next()
    }
  }