// =================================================
// Actions
// =================================================
export const actions = {
    // Get list of faq categories
    getListOfCategories({ state }) {
      return this.$axios.$get('/api/faqs/category')
    },
    // Get questions for specific categories
    getQuestions({ state }, categoryId) {
      return this.$axios.$get(`/api/faqs/${categoryId}?questionStatus=1`)
    },
    // Get relevent answer for selected question
    getAnswer({ state }, questionId) {
      return this.$axios.$get(`/api/faqs?id=${questionId}`)
    },
    searchQuestion({ state }, payload) {
      return this.$axios.$get(`/api/faqs/search/${payload.searchString}?page=${payload.page}&size=${payload.size}`)
    }
  }
  